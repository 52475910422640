import variables from '../variables';
import typography from '../typography';

const MuiChip = {
  styleOverrides: {
    root: () => ({
      backgroundColor: variables.colors.surface9,
      ...typography.caption,
      color: variables.colors.greyDark,
      height: 'auto',
      minHeight: 32,
      '& .MuiChip-label': {
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: 16,
        paddingRight: 16,
        whiteSpace: 'break-spaces',
      },
    }),
  },
};

export default MuiChip;
