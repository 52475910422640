import { styled } from '@mui/material/styles';
import { FormContainer } from '../../../../components/form';

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  'label:has(.MuiCheckbox-root)': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.surface8,
    paddingRight: 9,
    marginLeft: 0,
    '&:has(.Mui-checked)': {
      backgroundColor: theme.palette.common.greenLight,
    },
  },
  section: {
    marginBottom: theme.spacing(6),
    li: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default FormContainerStyled;
