import React, { useCallback } from 'react';
import {
  CardContent,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import {
  ProductFormProps, ProductFormValues,
} from '../../../../services/product-helpers';
import { ProductCode } from '../../../../services/models/product-code';
import {
  ProductCardActions,
  ProductFooterText,
  ProductOptionsContainer,
} from './product-panel.styles';
import EnableDecision from './enable-decision';
import DocumentLink from '../../../../components/documents-panel/document-link';
import { DecisionTable } from '../decision-table';
import SalesResources from './sales-resources';
import { ProductDto } from '../../../../services/models/product-dto';
import { useAppSelector } from '../../../../store/hooks';
import { selectEnquiryFor, selectPreSalesDecisionsFor } from '../../../../features/application-slice';
import IncomeProtectionFormPreSales from '../income-protection-form-presales';
import RealLifeForm from '../real-life-form';
import HealthCareForm from '../health-care-form';
import { PreSalesDecision } from '../../../../services/models/pre-sales-decision';
import OccupationLimits from './occupation-limits';
import DeferredPeriods from '../deferred-periods';
import useBusyState from '../../../../hooks/use-busy-state';
import { selectOnboardingToolSettings } from '../../../../features/settings-slice';
import { SalesResource } from '../../../../services/models/sales-resource';
import { Document } from '../../../../services/models/document';
import { FormErrors } from '../../../../components/form';
import { getEnquiryStatus } from '../../../../services/enquiry-helpers';
import { ProgressStatus } from '../../../../services/models/progress-status';

function getPreSalesFormContent(code: ProductCode, props: ProductFormProps) {
  switch (code) {
    case ProductCode.INCOME_PROTECTION: return <IncomeProtectionFormPreSales {...props} />;
    case ProductCode.REAL_LIFE: return (<RealLifeForm {...props} />);
    case ProductCode.HEALTH_CARE: return <HealthCareForm {...props} />;
    default: return null;
  }
}

function getDecisionContent(decision: PreSalesDecision[], product: ProductDto) {
  switch (product.code) {
    case ProductCode.INCOME_PROTECTION: return (
      <>
        <OccupationLimits decision={decision[0]} />
        <DeferredPeriods decisions={decision} productId={product.id} />
      </>
    );
    case ProductCode.REAL_LIFE: return <DecisionTable decision={decision[0]} productId={product.id} />;
    case ProductCode.HEALTH_CARE: return <DecisionTable decision={decision[0]} productId={product.id} hideNonStandardRating />;
    default: return null;
  }
}

export interface PreSalesProductFormProps {
  product: ProductDto;
  errors: string[],
  empty: boolean;
  documents?: Document[] | null | undefined,
  salesResources?: SalesResource[] | null | undefined,
  onProductChange: (changes: Partial<ProductFormValues>) => unknown;
  onEnableDecision: () => unknown;
  allowDecision: boolean;
  onConvertToFullApplication: () => unknown;
}

function PreSalesProductForm({
  product,
  errors,
  empty,
  documents = undefined,
  salesResources = undefined,
  onProductChange,
  allowDecision,
  onEnableDecision,
  onConvertToFullApplication,
}: PreSalesProductFormProps) {
  const { t } = useTranslation();
  const [converting, withConvertingState] = useBusyState();
  const onboardingEnabled = useAppSelector(selectOnboardingToolSettings)?.enabled;
  const firstApplicant = product.applicantIds![0];
  const preSalesDecisions = useAppSelector(selectPreSalesDecisionsFor(firstApplicant))?.filter((p) => p.product === product.productType);
  const enquiry = useAppSelector(selectEnquiryFor(firstApplicant));
  const hasPreSalesDecisions = !!preSalesDecisions && preSalesDecisions.length > 0;
  const enquiryStatus = getEnquiryStatus(enquiry);

  const onChangeCommitted = useCallback((name: string, value: unknown) => {
    onProductChange({ [name]: value });
  }, [onProductChange]);
  return (
    <>
      <CardContent>
        {getPreSalesFormContent(product.code!, { onChangeCommitted })}
        <FormErrors errors={errors} sx={{ marginTop: '16px' }} />
        {!hasPreSalesDecisions && (
          <ProductOptionsContainer>
            <EnableDecision
              decisionAvailable={!empty}
              disabled={!allowDecision || errors.length > 0}
              onClick={onEnableDecision}
              label={t('components.enableDecision.enableDecision')}
            />
          </ProductOptionsContainer>
        )}
      </CardContent>
      {hasPreSalesDecisions && (
        <Box sx={{ ml: 4, mr: 4, mb: 3.75 }}>
          {getDecisionContent(preSalesDecisions, product)}
        </Box>
      )}
      <ProductCardActions sx={{ gap: 2 }}>
        {documents?.map((document) => <DocumentLink key={document.name} document={document} />)}
        {salesResources && <SalesResources salesResources={salesResources} />}
        <Box sx={{ flex: '1 1 auto' }} />
        {onboardingEnabled && hasPreSalesDecisions && enquiryStatus === ProgressStatus.Complete && (
          <LoadingButton
            variant="outlined"
            color="secondary"
            loading={converting}
            onClick={withConvertingState(onConvertToFullApplication)}
          >
            {t('components.productPanel.convertToQuote')}
          </LoadingButton>
        )}
      </ProductCardActions>

      <ProductFooterText>
        <Typography variant="subtitle1" align="center">
          {t('components.productPanel.indicativeDecisionText')}
        </Typography>
      </ProductFooterText>
    </>
  );
}

export default PreSalesProductForm;
