import React from 'react';
import ClientDetailsForm from '../client-details-form';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  applicantSelected,
  getApplicationStatus,
  requestFinalDecision,
  selectAvailableApplication,
  updateDecision,
  validateProducts,
} from '../../../../features/application-slice';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { ApplicationDto } from '../../../../services/models/application-dto';
import LifestyleForm from '../lifestyle-form/lifestyle-form';
import {
  View,
  postUnderwritingDeclarationView,
  productView,
  ProceedFunction,
  ProceedOptions,
  amraDoctorDetailsView,
} from '../../../view';
import ConditionForm from '../condition-form/condition-form';
import { hasApplicantPreSalesDecision } from '../../../../services/decision-helpers';
import { ApplicationStatus } from '../../../../services/models/application-status';
import PreUnderwritingDeclaration from '../pre-underwriting-declaration';
import PostUnderwritingDeclaration from '../post-underwriting-declaration';
import AmraDeclaration from '../amra-declaration';
import ContactDetailsForm from '../contact-details-form/contact-details-form';
import AmraDoctorDetails from '../amra-doctor-details';
import { isPortalApplicationMissingJointApplicantPostcode } from '../../../../services/application-helpers';

export interface DrawerContentProps {
  view: View;
  application: ApplicationDto;
  applicant: ApplicantDto | null;
  proceedToNextView: ProceedFunction;
}

function DrawerContent({
  view, application, applicant, proceedToNextView,
}: DrawerContentProps) {
  const dispatch = useAppDispatch();
  const { preSalesDecisions, quoteDecision } = useAppSelector(selectAvailableApplication);
  const { drawer } = view;
  if (!drawer) {
    return null;
  }
  const updateDecisionAndProceed = async (options?: ProceedOptions, reValidateProducts?: boolean) => {
    if (hasApplicantPreSalesDecision(applicant?.id, preSalesDecisions) || quoteDecision) {
      await dispatch(updateDecision({ skipValidation: true, suppressErrors: true }));
    } else if (reValidateProducts) {
      await dispatch(validateProducts());
    }
    if (isPortalApplicationMissingJointApplicantPostcode(application) && application.applicants?.[1].id) {
      await dispatch(applicantSelected(application.applicants[1].id));
    }
    await dispatch(getApplicationStatus());
    proceedToNextView(options);
  };

  const requestFinalDecisionAndProceed = async (options?: ProceedOptions) => {
    await dispatch(requestFinalDecision());
    proceedToNextView(options);
  };

  switch (drawer.type) {
    case 'amraDeclaration': return (
      <AmraDeclaration
        applicationId={application.id}
        applicants={application.applicants}
        onSubmit={() => updateDecisionAndProceed({ target: amraDoctorDetailsView })}
        onCancel={() => updateDecisionAndProceed({ force: true })}
        onPrevious={(previous) => proceedToNextView({ target: previous })}
      />
    );
    case 'amraDoctorDetails': return (
      <AmraDoctorDetails
        applicationId={application.id}
        applicants={application.applicants}
        onSubmit={() => updateDecisionAndProceed({ target: postUnderwritingDeclarationView })}
        onCancel={() => updateDecisionAndProceed({ force: true })}
        onPrevious={(previous) => proceedToNextView({ target: previous })}
      />
    );
    default: // Fall through to applicant specific content
  }
  if (!applicant) {
    return null;
  }
  switch (drawer.type) {
    case 'clientDetailsBasic': return (
      <ClientDetailsForm
        applicationId={application.id}
        applicant={applicant}
        onSubmit={() => updateDecisionAndProceed({}, true)}
        fullDetails={application.status !== ApplicationStatus.PreSale}
      />
    );
    case 'contactDetails': return (
      <ContactDetailsForm
        application={application}
        applicant={applicant}
        onSubmit={() => updateDecisionAndProceed({ target: drawer.next })}
        onCancel={() => updateDecisionAndProceed({ force: true })}
        hideNavigation={!drawer.next}
      />
    );
    case 'lifestyle': return (
      <LifestyleForm
        applicant={applicant}
        onSubmit={updateDecisionAndProceed}
        onCancel={() => updateDecisionAndProceed({ force: true })}
      />
    );
    case 'condition': return (
      <ConditionForm
        key={drawer.conditionPath}
        applicant={applicant}
        conditionPath={drawer.conditionPath}
        onSubmit={updateDecisionAndProceed}
        onCancel={() => updateDecisionAndProceed({ force: true })}
        onPrevious={(previous) => proceedToNextView({ target: previous })}
        onNext={(next) => proceedToNextView({ target: next })}
      />
    );
    case 'preUnderwritingDeclaration': return (
      <PreUnderwritingDeclaration
        applicationId={application.id}
        applicant={applicant}
        onSubmit={() => updateDecisionAndProceed({ target: drawer.next })}
        onCancel={() => updateDecisionAndProceed({ force: true })}
      />
    );
    case 'postUnderwritingDeclaration': return (
      <PostUnderwritingDeclaration
        applicant={applicant}
        onSubmit={() => requestFinalDecisionAndProceed({ target: productView })}
        onCancel={() => updateDecisionAndProceed({ force: true })}
        onPrevious={(previous) => proceedToNextView({ target: previous })}
      />
    );
    default: return null;
  }
}

export default DrawerContent;
