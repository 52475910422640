import React, { FormEvent, useState } from 'react';
import {
  Alert,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { TextField } from '../../../components/form/fields';
import AuthLayout from './auth-layout';
import { FormContainer } from '../../../components/form';
import { loginSchema } from '../../../services/auth-helpers';
import { removeWhitespace } from '../../../utils/converters';

interface LoginProps {
  loading?: boolean;
  error?: string | null;
  onLogin: (email: string, password: string) => void;
  onForgotPassword: () => void;
}

function Login({
  onLogin,
  onForgotPassword,
  loading = false,
  error = null,
}: LoginProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = getValues();
    onLogin(removeWhitespace(email), password);
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.login.title')}</Typography>
          <Stack>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            <TextField
              name="email"
              type="text"
              sublabel={t('components.auth.login.email')}
              placeholder={t('components.auth.login.email')!}
              transform={removeWhitespace}
            />
            <TextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              sublabel={t('components.auth.login.password')}
              placeholder={t('components.auth.login.password')!}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ mr: 0 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <LoadingButton
              fullWidth
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValid}
            >
              {t('components.auth.login.signIn')}
            </LoadingButton>
          </Stack>
          <Button
            variant="text"
            color="info"
            size="small"
            sx={{ p: 0, mt: 2, mb: 2 }}
            onClick={onForgotPassword}
          >
            {t('components.auth.login.forgotPassword')}
          </Button>
          <Typography variant="h5">{t('components.auth.login.firstTimeSignIn')}</Typography>
          <Typography variant="body2">{t('components.auth.login.temporaryPassword')}</Typography>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default Login;
