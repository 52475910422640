import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/material/styles';

export const DrawerCard = styled(Card)(() => ({
  borderRadius: 0,
  border: 'none',
}));

export const DrawerCardHeader = styled(CardHeader)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.surface1,
  minHeight: 96,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  '& h3': {
    color: theme.palette.common.white,
  },
  '& p': {
    color: theme.palette.common.secondary400,
  },
  '.MuiBreadcrumbs-li, .MuiBreadcrumbs-separator': {
    color: theme.palette.common.secondary200,
  },
  '.MuiCardHeader-action': {
    '& button': {
      color: theme.palette.common.white,
    },
  },
})) as typeof CardHeader;

export const DrawerCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(6),
  position: 'relative',
  zIndex: 1,
  marginBottom: 90,
  '&:last-child': {
    paddingBottom: theme.spacing(6),
  },
  '& .MuiStack-root > .MuiBox-root > .MuiFormControl-root': {
    paddingRight: theme.spacing(24),
    [theme.breakpoints.down('xl')]: {
      paddingRight: 0,
    },
    [theme.breakpoints.down('lg')]: {
      paddingRight: 0,
    },
  },
}));

export const DrawerCardActions = styled(CardActions)(({ theme }) => ({
  alignItems: 'center',
  boxShadow: '0px -5px 13px -3px rgba(0,0,0,0.15)',
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  gap: theme.spacing(4),
  justifyContent: 'space-between',
  position: 'fixed',
  background: theme.palette.common.white,
  bottom: 0,
  width: '100%',
  zIndex: 10,
}));
