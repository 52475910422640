import React, { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  IconButton,
  InputAdornment,
  Stack,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
} from '@mui/material';
import { Delete } from '../../../../components/svg-icon';
import {
  DateField,
  NumberField,
  SelectField,
  TextField,
} from '../../../../components/form/fields';
import { BeneficiaryTable } from './beneficiary-form.styles';
import { OneDecimalsFormatter } from '../../../../components/form/inputs/number-input';
import { BeneficiaryFormData, beneficiaryValidationSchema } from '../../../../services/beneficiary-helpers';
import { BeneficiaryDto } from '../../../../services/models/beneficiary-dto';
import BeneficiarySelect from './beneficiary-select';

const relationships = [
  'Wife',
  'Husband',
  'Civil partner',
  'Partner/Cohabitant',
  'Child/Stepchild',
  'Parent',
  'Brother',
  'Sister',
  'Grandchild',
  'Other',
];

export interface BeneficiaryFormProps extends TableProps {
  beneficiary?: BeneficiaryFormData;
  applicationBeneficiaries?: BeneficiaryDto[];
  disabled?: boolean;
  onDelete: () => unknown;
  onUpdate: (beneficiary: BeneficiaryFormData) => unknown;
  hideHeader?: boolean;
}

function BeneficiaryForm({
  onDelete,
  onUpdate,
  beneficiary = undefined,
  applicationBeneficiaries = [],
  disabled = false,
  hideHeader = false,
  ...props
}: BeneficiaryFormProps) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {
      rowId: beneficiary?.rowId ?? '',
      firstName: beneficiary?.firstName ?? '',
      surname: beneficiary?.surname ?? '',
      dateOfBirth: beneficiary?.dateOfBirth ?? '',
      relationship: beneficiary?.relationship && !relationships.includes(beneficiary.relationship) ? 'Other' : beneficiary?.relationship ?? '',
      otherRelationship: beneficiary?.relationship && !relationships.includes(beneficiary.relationship) ? beneficiary?.relationship : undefined,
      percentage: beneficiary?.percentage ?? undefined,
    },
    resolver: yupResolver(beneficiaryValidationSchema) as any,
  });

  const {
    setValue,
    getValues,
    watch,
    trigger,
  } = formMethods;
  const [relationship, firstName, surname, dateOfBirth, percentage] = watch(['relationship', 'firstName', 'surname', 'dateOfBirth', 'percentage']);

  const handleChange = async (name: string, value: string | number | null) => {
    let newValue = value;
    if (name === 'percentage' && value && typeof value === 'number' && value > 100) {
      newValue = 100;
      setValue('percentage', newValue, { shouldValidate: true });
    }
    if (name === 'relationship' && value !== 'Other') {
      setValue('otherRelationship', undefined, { shouldValidate: true });
    }

    const updatedValues = {
      ...getValues(),
      [name as keyof BeneficiaryFormData]: newValue ?? null,
      relationship: getValues().relationship === 'Other' ? getValues().otherRelationship : getValues().relationship,
    };
    delete updatedValues.otherRelationship;
    onUpdate(updatedValues);
    if (name === 'dateOfBirth') {
      trigger('dateOfBirth');
    }
  };

  const handleSelectChange = (b: BeneficiaryDto | null) => {
    if (b) {
      setValue('firstName', b?.firstName ?? '', { shouldValidate: true });
      setValue('surname', b?.surname ?? '', { shouldValidate: true });
      setValue('dateOfBirth', b?.dateOfBirth ?? '', { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (percentage === null) {
      handleChange('percentage', percentage);
    }
  }, [percentage]);

  return (
    <FormProvider {...formMethods}>
      <BeneficiaryTable {...props}>
        {!hideHeader && (
          <TableHead>
            <TableRow>
              <TableCell>{t('components.beneficiaryForm.firstName')}</TableCell>
              <TableCell>{t('components.beneficiaryForm.surname')}</TableCell>
              <TableCell>{t('components.beneficiaryForm.relationship')}</TableCell>
              <TableCell>{t('components.beneficiaryForm.dateOfBirth')}</TableCell>
              <TableCell>{t('components.beneficiaryForm.percentage')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          <TableRow>
            <TableCell width={280} sx={{ verticalAlign: 'top', minWidth: '280px' }}>
              <TextField
                name="firstName"
                type="text"
                onChangeCommitted={handleChange}
                disabled={disabled}
              />
            </TableCell>
            <TableCell width={280} sx={{ verticalAlign: 'top', minWidth: '280px' }}>
              <TextField
                name="surname"
                type="text"
                onChangeCommitted={handleChange}
                disabled={disabled}
              />
            </TableCell>
            <TableCell width={280} sx={{ verticalAlign: 'top', minWidth: '280px' }}>
              <Stack direction="column" gap={2} flexWrap="wrap">
                <SelectField
                  name="relationship"
                  onChangeCommitted={handleChange}
                  options={relationships}
                  sx={{ width: '100%' }}
                />
                {relationship === 'Other' && (
                  <TextField
                    name="otherRelationship"
                    type="text"
                    onChangeCommitted={handleChange}
                    placeholder={t('components.beneficiaryForm.otherPlaceholder')!}
                    inputProps={{
                      maxLength: 20,
                    }}
                  />
                )}
              </Stack>
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
              <DateField
                name="dateOfBirth"
                disableFuture
                onChangeCommitted={handleChange}
                disabled={disabled}
              />
            </TableCell>
            <TableCell width={185} align="right" sx={{ verticalAlign: 'top', minWidth: 185 }}>
              <NumberField
                name="percentage"
                formatter={OneDecimalsFormatter}
                min={0}
                endAdornment={(<InputAdornment position="end">%</InputAdornment>)}
                sx={{ maxWidth: 100 }}
                inputProps={{
                  min: 0,
                  max: 100,
                  step: 0.1,
                  maxLength: 5,
                }}
                onChangeCommitted={handleChange}
              />
            </TableCell>
            <TableCell width={70} sx={{ verticalAlign: 'top' }}>
              <IconButton
                aria-label={t('common.delete')!}
                onClick={onDelete}
                sx={(theme) => ({ color: theme.palette.common.surface2 })}
              >
                <Delete fontSize="small" />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableBody>
      </BeneficiaryTable>
      <BeneficiarySelect
        fields={{
          firstName,
          surname,
          dateOfBirth,
          percentage,
          relationship,
        }}
        beneficiaries={applicationBeneficiaries}
        onChange={handleSelectChange}
      />
    </FormProvider>
  );
}

export default BeneficiaryForm;
