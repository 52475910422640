import { styled } from '@mui/material';

export const CommsList = styled('ul')(({ theme }) => ({
  padding: 0,
  paddingLeft: theme.spacing(2),
  '& > li': {
    color: theme.palette.secondary.main,
  },
}));

export default {};
