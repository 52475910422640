import React from 'react';
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { EvidenceDto } from '../../../../services/bff/models/evidence-dto';
import { EvidenceStatus } from '../../../../services/bff/models/evidence-status';
import { asClientDate, asClientTime } from '../../../../utils/converters';
import { StatusTableContainer } from './underwriting-status.styles';

function displayDateByStatus(evidence: EvidenceDto): string | null | undefined {
  switch (evidence.status!) {
    case EvidenceStatus.New:
    case EvidenceStatus.Open:
      return evidence.dates?.created;
    case EvidenceStatus.Waived:
      return evidence.dates?.waived;
    case EvidenceStatus.Requested:
      return evidence.dates?.requested;
    case EvidenceStatus.Received:
      return evidence.dates?.received;
    case EvidenceStatus.Assessed:
      return evidence.dates?.assessed;
    default: return evidence.dates?.created;
  }
}

function getUnderwritingStatusIcon(status: EvidenceStatus | undefined) {
  switch (status) {
    case EvidenceStatus.New: return <AccessTimeOutlinedIcon color="warning" />;
    case EvidenceStatus.Open: return <AccessTimeOutlinedIcon color="warning" />;
    case EvidenceStatus.Requested: return <AccessTimeOutlinedIcon color="warning" />;
    case EvidenceStatus.Received: return <CheckCircleOutlineIcon color="success" />;
    case EvidenceStatus.Assessed: return <CheckCircleOutlineIcon color="success" />;
    case EvidenceStatus.Waived: return <CheckCircleOutlineIcon color="success" />;
    default: return null;
  }
}

const hiddenStatuses = [EvidenceStatus.Rejected, EvidenceStatus.Expired] as string[];

function filterEvidenceStatus(evidences: EvidenceDto[]): EvidenceDto[] {
  return evidences.filter((evidence) => evidence.status && !hiddenStatuses.includes(evidence.status));
}

interface UnderwritingStatusTableProps {
  evidences: EvidenceDto[];
}

function UnderwritingStatusTable({ evidences }: UnderwritingStatusTableProps) {
  const { t } = useTranslation();
  return (
    <StatusTableContainer>
      <Table>
        <TableBody>
          {filterEvidenceStatus(evidences).map((evidence) => (
            <TableRow key={evidence.id}>
              <TableCell width={300}>
                {evidence.evidenceTypeName}
              </TableCell>
              <TableCell sx={{ backgroundColor: 'common.background4' }} width={185}>
                <Stack direction="row" alignItems="center" gap={0.5}>
                  {getUnderwritingStatusIcon(evidence.status)}
                  {t(`components.underwritingStatusTable.evidenceStatus.${evidence.status}`)}
                </Stack>
              </TableCell>
              <TableCell width={200}>
                {asClientDate(displayDateByStatus(evidence))}
              </TableCell>
              <TableCell width={200}>
                {asClientTime(displayDateByStatus(evidence))}
              </TableCell>
              <TableCell>
                {evidence.providerUpdate}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StatusTableContainer>
  );
}

export default UnderwritingStatusTable;
