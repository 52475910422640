import { alpha, styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const PreDisclosedPanelStyled = styled(Stack)(({ theme }) => ({
  '& .MuiCard-root': {
    border: 'none',
  },
  '& .MuiCardContent-root': {
    backgroundColor: theme.palette.common.greenLight,
    padding: theme.spacing(3),
    '& ul': {
      paddingLeft: theme.spacing(3),
      marginBottom: 0,
      '& > li': {
        fontWeight: 'bold',
      },
    },
  },
  '& .MuiCardActions-root': {
    backgroundColor: alpha(theme.palette.common.greenLight, 0.5),
    padding: theme.spacing(3),
    minHeight: 'auto',
  },
}));

export default PreDisclosedPanelStyled;
