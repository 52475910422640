import React from 'react';
import {
  Accordion,
  AccordionDetails,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import { ProductDto } from '../../../../services/models/product-dto';
import { ProductCode } from '../../../../services/models/product-code';
import { HealthCareDetails, IncomeProtectionDetails, RealLifeDetails } from '../../../../components/product-details';
import { ProductQuoteDecision } from '../../../../services/models/product-quote-decision';
import { CommissionDetails } from '../../../../components/commission-details';
import ProductDecisionAndPremium from './product-decision-and-premium';
import { DecisionCardRow, DecisionDetailsAccordionSummary, ProductQuoteDecisionTableStyled } from './both-clients-products.styles';
import ProductCardTitle from '../../../../components/product-card-title';
import { hasApplicantReasonDecisions, reasonDecisionIsNotStandard } from '../../../../services/decision-helpers';
import { Agency } from '../../../../services/models/agency';
import { getOrderedProductApplicants } from '../../../../services/product-helpers';
import { getApplicantNamePossessiveCase } from '../../../../services/application-helpers';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import ProductQuoteKeyDecisonTable from '../decision-table/product-quote-key-decision-table';

function getCardContent(product: ProductDto, decisionCoverAmount: number | undefined) {
  switch (product.code) {
    case ProductCode.INCOME_PROTECTION: return <IncomeProtectionDetails product={product} decisionCoverAmount={decisionCoverAmount} />;
    case ProductCode.REAL_LIFE: return (<RealLifeDetails product={product} decisionCoverAmount={decisionCoverAmount} />);
    case ProductCode.HEALTH_CARE: return <HealthCareDetails product={product} />;
    default: return null;
  }
}

export interface ProductDecisionCardProps {
  applicantId: string;
  applicants: ApplicantDto[]
  product: ProductDto;
  decision?: ProductQuoteDecision | null;
  agency: Agency | null | undefined;
}

function ProductDecisionCard({
  applicantId,
  applicants,
  product,
  decision = null,
  agency,
}: ProductDecisionCardProps) {
  const { t } = useTranslation();
  const showDecisionDetails = decision?.applicantDecisions.some((ad) => ad.evidences.length > 0 || ad.reasonDecisions.some(reasonDecisionIsNotStandard));
  const jointLife = product.applicantIds ? product.applicantIds.length > 1 : false;
  const productApplicants = getOrderedProductApplicants(product, applicants, applicantId, jointLife);

  return (
    <Card elevation={0}>
      <CardHeader
        titleTypographyProps={{ variant: 'h3', marginY: 0.5 }}
        title={<ProductCardTitle product={product} />}
      />
      <CardContent sx={{ pt: 2 }}>
        {getCardContent(product, decision?.coverAmount)}
      </CardContent>
      <DecisionCardRow decision={decision?.decision}>
        <ProductDecisionAndPremium decision={decision} />
      </DecisionCardRow>
      {showDecisionDetails && decision && productApplicants
        .filter((productApplicant) => hasApplicantReasonDecisions(productApplicant.id, decision))
        .map((applicant) => (
          <Accordion disableGutters key={applicant.id}>
            <DecisionDetailsAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${product.id}-decision-content`}
              id={`${product.id}-decision-header`}
            >
              <Typography variant="h4">{jointLife ? `${getApplicantNamePossessiveCase(applicant)} ${t('components.productDecisionCard.decisionDetails')}` : t('components.productDecisionCard.decisionDetails')}</Typography>
            </DecisionDetailsAccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
              <ProductQuoteKeyDecisonTable
                key={applicant.id}
                applicantId={applicant.id}
                decision={decision}
                titlePrefix={applicant ? `${getApplicantNamePossessiveCase(applicant)} ` : undefined}
              />
              <ProductQuoteDecisionTableStyled applicantId={applicant.id} decision={decision} />
            </AccordionDetails>
          </Accordion>
        ))}
      <CommissionDetails
        productId={product.id}
        commission={decision?.commission}
        agency={agency}
      />
    </Card>
  );
}

export default ProductDecisionCard;
