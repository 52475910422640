import { Stack, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import MuiTableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '../../../../components/types';
import { DecisionType } from '../../../../services/product-helpers';

export const RowLabelWidth = 240;

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  overflowX: 'hidden',
  '& table': {
    position: 'relative',
    '& th': {
      borderRight: `1px solid ${theme.palette.common.secondary200}`,
      borderBottom: `1px solid ${theme.palette.common.secondary200}`,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(4),
      verticalAlign: 'top',
      minWidth: 280,
      maxWidth: 280,
      width: 280,
      ...theme.typography.h4,
    },
    '& > thead > tr > td,& > thead > tr > th': {
      backgroundColor: '#fafafb',
      borderBottom: 'none',
    },
    '& > thead > tr:last-of-type > td, & > thead > tr:last-of-type > th': {
      borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    },
    '& > thead > tr > td': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    '& > tbody > tr:first-of-type > td, th': {
      borderTop: `1px solid ${theme.palette.common.secondary200}`,
    },
    '& > tbody > tr > td': {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      verticalAlign: 'top',
      borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    },
    '& > tbody > tr > th': {
      borderBottom: `1px solid ${theme.palette.common.secondary200}`,
    },
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  display: 'flex',
  marginTop: theme.spacing(2),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  marginBottom: theme.spacing(-4),
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  '& > th,& > td': {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  },
}));

export const TableCellInner = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '& > span': {
    display: 'flex',
    alignItems: 'center',
    fontFamily: theme.typography.h5.fontFamily,
    marginLeft: theme.spacing(2),
    textAlign: 'right',
    '& span': {
      fontFamily: theme.typography.h5.fontFamily,
    },
  },
}));

export type DecisionContainerProps = {
  decision?: string | null | undefined;
};

export function getDecisionColor(decision: string | null | undefined, theme: Theme) {
  switch (decision) {
    case DecisionType.STANDARD:
    case DecisionType.NON_STANDARD:
      return theme.palette.common.greenLight;
    case DecisionType.EVIDENCE_REQUIRED:
    case DecisionType.REFER: return theme.palette.common.orangeLight;
    case DecisionType.DECLINE:
    case DecisionType.POSTPONE: return theme.palette.common.redLight;
    default: return '#fafafb';
  }
}

export const DecisionContainer = styled(Stack, {
  shouldForwardProp: (prop) => shouldForwardProp<DecisionContainerProps>(['decision'], prop),
})<DecisionContainerProps>(({ theme, decision }) => ({
  backgroundColor: getDecisionColor(decision, theme),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
}));

export const EvidenceRow = styled(MuiTableRow)(({ theme }) => ({
  '& > th.MuiTableCell-root,& > td.MuiTableCell-root': {
    backgroundColor: theme.palette.common.yellow200,
    borderRight: 'none',
    verticalAlign: 'baseline',
  },
}));

export const ExclusionsRow = styled(MuiTableRow)(() => ({
  '& > th.MuiTableCell-root,& > td.MuiTableCell-root': {
    borderRight: 'none',
    verticalAlign: 'baseline',
    borderBottom: 'none',
  },
}));

export const ExclusionsContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
}));

export const TotalRatingDecisionCardContainer = styled(Box)(() => ({
  '& > div': {
    display: 'inline-block',
  },
}));

export default TableContainerStyled;
