import React, { Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  TableBody,
  TableCell,
  TableCellProps,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import {
  ApplicationTable as StyledApplicationTable,
  StatusChip,
} from './application-table.styles';
import { ApplicationDto as ApplicationSearchDto } from '../../../../services/bff/models/application-dto';
import { asClientDate, parseServerDate, todayDate } from '../../../../utils/converters';
import { getTotalsValue, isValidPolicyStartDate } from '../../../../services/application-helpers';
import { TotalsValue } from '../../../../services/bff/models/totals-value';

export type SortProps = {
  orderBy?: string | null,
  descending?: boolean | null,
  onSort: (orderBy: string | null, descending: boolean | null) => void,
};

export function getValueFromStringArray(index: number, arr: string[] | undefined): string {
  if (!arr) return '-';
  try {
    return arr[index];
  } catch {
    return '-';
  }
}

function formatExpiryDate(date: string, expiryText: string, expiredText: string): string {
  if (isValidPolicyStartDate(todayDate().startOf('day').format(), date)) {
    return `${-todayDate().diff(parseServerDate(date), 'days')} ${expiryText}`;
  }
  return expiredText;
}

interface ApplicantTableCellProps {
  applicantName: string | null | undefined;
  products: string | null | undefined;
  lifeNumber: number;
}

function ApplicantTableCell({ applicantName, products, lifeNumber }: ApplicantTableCellProps) {
  return (
    <TableCell>
      {applicantName && <Typography variant="body2" component="span" sx={{ display: 'block' }} data-testid={`life${lifeNumber}-name`}>{applicantName}</Typography>}
      {products && <Typography variant="caption" component="span" color="common.surface3" data-testid={`life${lifeNumber}-cover`}>{products}</Typography>}
    </TableCell>
  );
}

interface CommissionTableCellProps extends TableCellProps { totals: TotalsValue | null | undefined; }

function TotalsTableCell({ totals, ...props }: CommissionTableCellProps) {
  if (!totals) return <TableCell />;
  const output = getTotalsValue(totals);
  return (
    <TableCell {...props}>
      {output}
    </TableCell>
  );
}

interface ApplicationTableProps {
  applications: ApplicationSearchDto[];
  onSelectApplication: (applicationId: string) => void;
  sortProps?: SortProps;
  showAdviserFirmDetails?: boolean;
}

function ApplicationTable({
  applications,
  onSelectApplication,
  sortProps = undefined,
  showAdviserFirmDetails = false,
}: ApplicationTableProps, ref: Ref<HTMLDivElement>) {
  const { t } = useTranslation();

  const getDirection = (column: string): 'asc' | 'desc' | undefined => {
    if (sortProps?.orderBy === column && sortProps.descending) {
      return sortProps.descending ? 'desc' : 'asc';
    }
    return undefined;
  };

  const renderTableHeader = (key: string, label: string, tableCellProps?: TableCellProps) => (
    <TableCell
      {...tableCellProps}
      component="th"
      key={key}
    >
      {sortProps ? (
        <TableSortLabel
          active={sortProps.orderBy === key}
          direction={getDirection(key)}
          onClick={() => sortProps.onSort(key, !sortProps.descending)}
        >
          {label}
        </TableSortLabel>
      ) : label}
    </TableCell>
  );

  return (
    <TableContainer ref={ref} sx={{ maxHeight: 'calc(100vh - 350px)' }}>
      <StyledApplicationTable size="small" stickyHeader role="table">
        <TableHead>
          <TableRow>
            {renderTableHeader('id', t('components.applicationTable.table.headers.reference'), { width: '200' })}
            {renderTableHeader('thirdPartyRef', t('components.applicationTable.table.headers.yourReference'))}
            {renderTableHeader('applicant1Name', t('components.applicationTable.table.headers.applicant1'), { width: '15%' })}
            {renderTableHeader('applicant2Name', t('components.applicationTable.table.headers.applicant2'), { width: '15%' })}
            {renderTableHeader('dates.created', t('components.applicationTable.table.headers.dateStarted'), { width: '190' })}
            {showAdviserFirmDetails && (
              <>
                {renderTableHeader('firmName', t('components.applicationTable.table.headers.firmName'), { width: '200' })}
                {renderTableHeader('adviserName', t('components.applicationTable.table.headers.adviserName'), { width: '200' })}
              </>
            )}
            {renderTableHeader('premium', t('components.applicationTable.table.headers.premium'), { width: '200' })}
            {renderTableHeader('commission', t('components.applicationTable.table.headers.commission'), { width: '200' })}
            {renderTableHeader('status', t('components.applicationTable.table.headers.stage'), { width: '150' })}
          </TableRow>
        </TableHead>
        <TableBody>
          {applications && applications.map((application) => (
            <TableRow data-testid="searchRow" key={application.applicationId} onClick={() => onSelectApplication(application.applicationId)}>
              <TableCell data-testid="applicationID">
                <Button
                  variant="text"
                  color="inherit"
                  size="small"
                  onClick={() => onSelectApplication(application.applicationId)}
                >
                  {application.applicationId}
                </Button>
              </TableCell>
              <TableCell data-testid="yourRef">{application.thirdPartyRef}</TableCell>
              <ApplicantTableCell applicantName={application?.applicant1Name} products={application?.applicant1Products} lifeNumber={1} />
              <ApplicantTableCell applicantName={application?.applicant2Name} products={application?.applicant2Products} lifeNumber={2} />
              <TableCell>
                <Typography variant="body2" component="span" sx={{ display: 'block' }} data-testid="createdDate">
                  {asClientDate(application.created)}
                </Typography>
                {application.expiryDate && (
                  <Typography variant="caption" component="span" color="common.surface3">
                    {formatExpiryDate(application.expiryDate, t('components.applicationTable.expiry'), `${t('components.applicationTable.expiredOn')} ${asClientDate(application.expiryDate)}`)}
                  </Typography>
                )}
              </TableCell>
              {showAdviserFirmDetails && (
                <>
                  <TableCell data-testid="firmName">{application.firmName}</TableCell>
                  <TableCell data-testid="adviserName">{application.adviserName}</TableCell>
                </>
              )}
              <TotalsTableCell totals={application?.totals?.premium} data-testid="premiums" />
              <TotalsTableCell totals={application?.totals?.commission} data-testid="commission" />
              <TableCell data-testid="appStage"><StatusChip label={t(`common.applicationStatus.${application.status}`)} variant="outlined" size="small" sx={{ minWidth: 80 }} /></TableCell>
            </TableRow>
          ))}
          {applications && applications.length === 0 && (
            <TableRow>
              <TableCell colSpan={showAdviserFirmDetails ? 10 : 8}>{t('components.applicationTable.table.noApplications')}</TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledApplicationTable>
    </TableContainer>
  );
}

export default forwardRef(ApplicationTable);
