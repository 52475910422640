import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { isAxiosError } from 'axios';
import { ApplicationDto } from '../services/models/application-dto';
import { ApplicantDto } from '../services/models/applicant-dto';
import { ProductDto } from '../services/models/product-dto';
// eslint-disable-next-line import/no-cycle
import { AppDispatch, AppThunk, RootState } from '../store';
// eslint-disable-next-line import/no-cycle
import applicationApi from '../services/application-api';
import { ProductWithoutIdDto } from '../services/models/product-without-id-dto';
import enquiryApi from '../services/enquiry-api';
import decisionApi from '../services/decision-api';
import { Enquiry } from '../services/models/enquiry';
import {
  presalesDisclosuresPath,
  getMultipleAnswer,
  removeAnswer,
  toggleAnswer,
  getAnswersToRemoveCondition,
  areAllEnquiriesClosed,
} from '../services/enquiry-helpers';
import { EnquiryLine } from '../services/models/enquiry-line';
import { PreSalesDecisionResult } from '../services/models/pre-sales-decision-result';
import { ApplicationStatus } from '../services/models/application-status';
import { OperationType } from '../services/models/operation-type';
import { StatusDto } from '../services/models/status-dto';
import { asStringOrNull } from '../utils/converters';
import { UnderwriteMeEnquiryType } from '../services/models/underwrite-me-enquiry-type';
import { AddressDto } from '../services/models/address-dto';
import { ContactDetailsDto } from '../services/models/contact-details-dto';
import { QuoteDecision } from '../services/models/quote-decision';
import {
  ProductError,
  ProductErrorType,
  isValid,
  toProductErrors,
} from '../services/product-helpers';
import { TotalPremium } from '../services/models/total-premium';
import { ProductQuoteDecision } from '../services/models/product-quote-decision';
// eslint-disable-next-line import/no-cycle
import {
  getAddressPatchOperations,
  getAmraDoctorsPatchOperations,
  getAmraProgressStatus,
  getApplicantAddress,
  getApplicantMedicalDetailsAddress,
  getApplicantStatus,
  getEnquiryCollation,
  hasInvalidProduct,
  isApplicantMissingContactDetails,
  isApplicantMissingLifestyleAnswers,
  isApplicantValid,
  isPostUnderwritingDeclarationEnabled,
  isPreSalesAppliction,
} from '../services/application-helpers';
import { Operation } from '../services/models/operation';
import { EnquiryCollation } from '../services/models/enquiry-collation';
import {
  ProceedOptions,
  View,
  contactDetailsView,
  emptyView,
  lifestyleView,
  newApplicantView,
  postUnderwritingDeclarationView,
  preUnderwritingDeclarationView,
  productView,
} from '../routes/view';
import { BankDetailsDto } from '../services/models/bank-details-dto';
import agencyApi from '../services/agency-api';
import { MedicalDetailsDto } from '../services/models/medical-details-dto';
import { ApplicantTotalPremium } from '../services/models/applicant-total-premium';
import { ProgressStatus } from '../services/models/progress-status';
import { toPatchOperations } from '../services/http';
import { AddEvidenceDocumentDto } from '../services/bff/models/add-evidence-document-dto';
import documentApi from '../services/document-api';
import { readFileContent } from '../services/file-helper';
import { UpdateApplicationAgencyCommissionTermsDto } from '../services/models/update-application-agency-commission-terms-dto';
import { ApplicationAdviserDetailsDto } from '../services/bff/models/application-adviser-details-dto';
import { ThirdPartyPayerDto } from '../services/models/third-party-payer-dto';
import { CommissionTermsReference } from '../services/models/commission-terms-reference';
import { BeneficiaryDto } from '../services/models/beneficiary-dto';
import { BeneficiaryNominationDto } from '../services/models/beneficiary-nomination-dto';
import beneficiaryApi from '../services/beneficiary-api';

export const VALID = { status: 'valid' } as const;
export const UPDATING = { status: 'updating' } as const;
export type InvalidProductsStatus = {
  status: 'invalid';
  errors: ProductError[];
};

export type ProductsStatus =
  typeof VALID |
  typeof UPDATING |
  InvalidProductsStatus;

export type ApplicantIndex = 0 | 1 | null;
function toApplicantIndex(index: number | undefined): ApplicantIndex {
  return (index === 0 || index === 1) ? index : null;
}

export const NONE = { status: 'none' } as const;
export const LOADING = { status: 'loading' } as const;

export type AvailableApplication = {
  status: 'available',
  application: ApplicationDto,
  applicationStatus: StatusDto,
  enquiries: Record<string, Enquiry>,
  preSalesDecisions: Record<string, PreSalesDecisionResult>,
  quoteDecision: QuoteDecision | null,
  productsStatus: ProductsStatus,
  activeApplicantIndex: ApplicantIndex,
  view: View,
  adviserDetails: ApplicationAdviserDetailsDto | null;
};

export type ApplicationState =
  typeof NONE |
  typeof LOADING |
  AvailableApplication;

export const APPLICATION_SLICE_NAME = 'APPLICATION';

type Decisions = Pick<AvailableApplication, 'preSalesDecisions' | 'quoteDecision'>;

export interface PatchAddressPayload<K extends keyof AddressDto> {
  id: string;
  property: K;
  value: AddressDto[K];
}

export interface PatchApplicantPayload<K extends keyof ApplicantDto> {
  id: string;
  property: K;
  value: ApplicantDto[K];
}

export interface PatchApplicationPayload<K extends keyof ApplicationDto> {
  property: K;
  value: ApplicationDto[K];
}

export interface PatchBankDetailstPayload<K extends keyof BankDetailsDto> {
  property: K;
  value: BankDetailsDto[K];
}

export interface PatchContactDetailsPayload<K extends keyof ContactDetailsDto> {
  applicantId: string;
  property: K;
  value: ContactDetailsDto[K];
}

export interface PatchMedicalDetailsPayload<K extends keyof MedicalDetailsDto> {
  applicantId: string;
  property: K;
  value: MedicalDetailsDto[K];
}

export interface ProductStatusPayload {
  productId: string;
  errors: ProductError[]
}

export interface BeneficiaryNominationsPayload {
  productId: string;
  beneficiaries: BeneficiaryNominationDto[]
}

/* eslint-disable no-param-reassign */
export const applicationSlice = createSlice({
  name: APPLICATION_SLICE_NAME,
  initialState: NONE as ApplicationState,
  reducers: {
    addressAdded(state, { payload }: PayloadAction<AddressDto>) {
      if (state.status === 'available') {
        const { application } = state;
        if (Array.isArray(application.addresses)) {
          application.addresses!.push(payload);
        } else {
          application.addresses = [payload];
        }
      }
    },
    addressUpdated(state, { payload }: PayloadAction<AddressDto>) {
      if (state.status === 'available') {
        const { application: { addresses } } = state;
        const index = addresses!.findIndex((a) => a.id === payload.id);
        if (addresses && index !== -1) {
          addresses[index] = payload;
        }
      }
    },
    addressPatched<K extends keyof AddressDto>(state: ApplicationState, { payload }: PayloadAction<PatchAddressPayload<K>>) {
      if (state.status === 'available') {
        const { application: { addresses } } = state;
        const address = addresses!.find((a) => a.id === payload.id);
        if (address) {
          address[payload.property] = payload.value;
        }
      }
    },
    adviserDetailsUpdated(state, { payload }: PayloadAction<ApplicationAdviserDetailsDto>) {
      if (state.status === 'available') {
        state.adviserDetails = payload;
      }
    },
    applicantAdded(state, { payload }: PayloadAction<ApplicantDto>) {
      if (state.status === 'available') {
        const { application: { applicants } } = state;
        applicants.push(payload);
      }
    },
    applicantPatched<K extends keyof ApplicantDto>(state: ApplicationState, { payload }: PayloadAction<PatchApplicantPayload<K>>) {
      if (state.status === 'available') {
        const { application: { applicants } } = state;
        const applicant = applicants.find((a) => a.id === payload.id);
        if (applicant) {
          applicant[payload.property] = payload.value;
        }
      }
    },
    applicationPatched<K extends keyof ApplicationDto>(state: ApplicationState, { payload }: PayloadAction<PatchApplicationPayload<K>>) {
      if (state.status === 'available') {
        const { application } = state;
        application[payload.property] = payload.value;
      }
    },
    applicationUpdated(state: ApplicationState, { payload }: PayloadAction<ApplicationDto>) {
      if (state.status === 'available') {
        state.application = payload;
      }
    },
    applicantSelected(state, { payload }: PayloadAction<string | null>) {
      if (state.status === 'available') {
        if (!payload) {
          state.activeApplicantIndex = null;
        } else {
          const index = state.application.applicants.findIndex((a) => a.id === payload);
          state.activeApplicantIndex = toApplicantIndex(index);
        }
      }
    },
    applicantUpdated(state, { payload }: PayloadAction<ApplicantDto>) {
      if (state.status === 'available') {
        const { application: { applicants } } = state;
        const index = applicants.findIndex((a) => a.id === payload.id);
        if (index !== undefined && index >= 0) {
          applicants[index] = payload;
        }
      }
    },
    applicationStatusUpdated(state, { payload }: PayloadAction<StatusDto>) {
      if (state.status === 'available') {
        const { applicationStatus, application } = state;
        applicationStatus.applicants = payload.applicants;
        applicationStatus.application = payload.application;
        application.status = payload.application.status;
      }
    },
    bankDetailsUpdated(state: ApplicationState, { payload }: PayloadAction<BankDetailsDto>) {
      if (state.status === 'available') {
        const { application } = state;
        application.bankDetails = payload;
      }
    },
    beneficiaryAdded(state: ApplicationState, { payload }: PayloadAction<BeneficiaryDto>) {
      if (state.status === 'available') {
        const { application: { beneficiaryDetails } } = state;
        if (beneficiaryDetails) {
          if (beneficiaryDetails.beneficiaries) {
            beneficiaryDetails.beneficiaries.push(payload);
          } else {
            beneficiaryDetails.beneficiaries = [payload];
          }
        }
      }
    },
    beneficiaryDeleted(state: ApplicationState, { payload }: PayloadAction<BeneficiaryDto>) {
      if (state.status === 'available') {
        const { application } = state;
        const beneficiaryIndex = application?.beneficiaryDetails?.beneficiaries?.findIndex((beneficiary) => beneficiary.id === payload.id);
        if (application?.beneficiaryDetails?.beneficiaries && beneficiaryIndex !== undefined && beneficiaryIndex !== -1) {
          application.beneficiaryDetails.beneficiaries.splice(beneficiaryIndex, 1);
        }
      }
    },
    beneficiaryUpdated(state, { payload }: PayloadAction<BeneficiaryDto>) {
      if (state.status === 'available') {
        const { application: { beneficiaryDetails } } = state;
        const index = beneficiaryDetails?.beneficiaries?.findIndex((b) => b.id === payload.id);
        if (index !== undefined && index >= 0 && beneficiaryDetails?.beneficiaries) {
          beneficiaryDetails.beneficiaries[index] = payload;
        }
      }
    },
    beneficiariesUpdated(state, { payload }: PayloadAction<BeneficiaryDto[]>) {
      if (state.status === 'available') {
        const { application: { beneficiaryDetails } } = state;
        if (beneficiaryDetails) {
          beneficiaryDetails.beneficiaries = payload;
        }
      }
    },
    commissionTermsUpdated(state: ApplicationState, { payload }: PayloadAction<CommissionTermsReference>) {
      if (state.status === 'available') {
        const { application } = state;
        if (application.agency) {
          application.agency.commissionTerms = payload;
        }
      }
    },
    contactDetailsPatched<K extends keyof ContactDetailsDto>(state: ApplicationState, { payload }: PayloadAction<PatchContactDetailsPayload<K>>) {
      if (state.status === 'available') {
        const { application } = state;
        const { applicants } = application;
        const applicant = applicants.find((a) => a.id === payload.applicantId);
        if (applicant) {
          applicant.contactDetails![payload.property] = payload.value;
        }
      }
    },
    decisionsUpdated(state, { payload }: PayloadAction<Decisions>) {
      if (state.status === 'available') {
        state.preSalesDecisions = payload.preSalesDecisions;
        state.quoteDecision = payload.quoteDecision;
      }
    },
    enquiryUpdated(state, { payload }: PayloadAction<Enquiry>) {
      if (state.status === 'available') {
        const { enquiries } = state;
        enquiries[payload.enquiryId] = payload;
      }
    },
    enquiriesUpdated(state, { payload }: PayloadAction<Record<string, Enquiry>>) {
      if (state.status === 'available') {
        const { enquiries } = state;
        Object.keys(payload).forEach((enquiryId) => {
          enquiries[enquiryId] = payload[enquiryId];
        });
      }
    },
    productAdded(state, { payload }: PayloadAction<ProductDto>) {
      if (state.status === 'available') {
        const { application } = state;
        application.products.push(payload);
      }
    },
    productDeleted(state, { payload }: PayloadAction<ProductDto>) {
      if (state.status === 'available') {
        const { application } = state;
        const productIndex = application.products.findIndex((product) => product.id === payload.id);
        if (productIndex !== -1) {
          application.products.splice(productIndex, 1);
        }
      }
    },
    productUpdated(state: ApplicationState, { payload }: PayloadAction<ProductDto>) {
      if (state.status === 'available') {
        const { application: { products } } = state;
        const productIndex = products.findIndex((product) => product.id === payload.id);
        if (productIndex !== -1) {
          products[productIndex] = payload;
        }
      }
    },
    productStatusUpdated(state, { payload }: PayloadAction<ProductStatusPayload>) {
      if (state.status === 'available') {
        const { application, productsStatus } = state;
        const otherProductErrors = productsStatus.status === 'invalid' ? productsStatus.errors.filter((error) => error.id !== payload.productId) : [];
        if (payload.errors.length > 0 || otherProductErrors.length > 0 || hasInvalidProduct(isPreSalesAppliction(application.status), application)) {
          state.productsStatus = {
            status: 'invalid',
            errors: [
              ...otherProductErrors,
              ...payload.errors,
            ],
          };
        } else {
          state.productsStatus = VALID;
        }
      }
    },
    productsStatusUpdated(state, { payload }: PayloadAction<ProductsStatus>) {
      if (state.status === 'available') {
        state.productsStatus = payload;
      }
    },
    beneficiaryNominationUpdated(state, { payload }: PayloadAction<BeneficiaryNominationsPayload>) {
      if (state.status === 'available') {
        const { application: { beneficiaryDetails } } = state;
        if (beneficiaryDetails?.nominations) {
          const currentNominations = beneficiaryDetails?.nominations.filter((nomination) => nomination.productId !== payload.productId);
          beneficiaryDetails.nominations = [...currentNominations, ...payload.beneficiaries];
        }
      }
    },
    quoteEffectiveDateUpdated(state, { payload }: PayloadAction<string>) {
      if (state.status === 'available') {
        const { application } = state;
        if (application.dates) {
          application.dates.quoteEffective = payload;
        }
      }
    },
    thirdPartyPayerUpdated(state, { payload }: PayloadAction<ThirdPartyPayerDto | null>) {
      if (state.status === 'available') {
        const { application } = state;
        application.thirdPartyPayer = payload;
      }
    },
    thirdPartyRefUpdated(state, { payload }: PayloadAction<string>) {
      if (state.status === 'available') {
        const { application } = state;
        application.thirdPartyRef = payload;
      }
    },
    updated(state, { payload }: PayloadAction<ApplicationState>) {
      return payload;
    },
    viewUpdated(state, { payload }: PayloadAction<View>) {
      if (state.status === 'available') {
        state.view = payload;
      }
    },
  },
});
/* eslint-enable no-param-reassign */

export const {
  addressAdded,
  addressUpdated,
  addressPatched,
  adviserDetailsUpdated,
  applicantAdded,
  applicantPatched,
  applicantUpdated,
  applicationPatched,
  applicationUpdated,
  applicationStatusUpdated,
  bankDetailsUpdated,
  beneficiaryAdded,
  beneficiaryDeleted,
  beneficiaryUpdated,
  beneficiariesUpdated,
  commissionTermsUpdated,
  contactDetailsPatched,
  decisionsUpdated,
  enquiriesUpdated,
  enquiryUpdated,
  productAdded,
  beneficiaryNominationUpdated,
  productDeleted,
  productStatusUpdated,
  productsStatusUpdated,
  productUpdated,
  quoteEffectiveDateUpdated,
  thirdPartyPayerUpdated,
  thirdPartyRefUpdated,
  updated,
  viewUpdated,
  applicantSelected,
} = applicationSlice.actions;

export const selectApplicationState = (state: RootState) => state.application;

export const selectAvailableApplication = (state: RootState) => state.application as AvailableApplication;

export const selectApplication: (state: RootState) => ApplicationDto = (state: RootState) => selectAvailableApplication(state).application;

export const selectApplicant = (application: ApplicationDto, applicantId: string) => application.applicants.find((applicant) => applicant.id === applicantId);

function getApplicantByIndex(application: ApplicationDto, index: ApplicantIndex): ApplicantDto | null {
  return index !== null ? application.applicants[index] : null;
}

export const selectActiveApplicant = (state: RootState) => {
  const { application, activeApplicantIndex } = selectAvailableApplication(state);
  return getApplicantByIndex(application, activeApplicantIndex);
};

export const selectInactiveApplicant = (state: RootState): ApplicantDto | null | undefined => {
  const activeApplicant = selectActiveApplicant(state);
  const { application } = selectAvailableApplication(state);
  return activeApplicant ? application.applicants.find((applicant) => applicant.id !== activeApplicant.id) : null;
};

export const selectAddress = (application: ApplicationDto, addressId: string) => application.addresses?.find((address) => address.id === addressId);

export const selectEnquiryFor = (applicantId: string) => (state: RootState) => {
  const { application, enquiries } = selectAvailableApplication(state);
  const applicant = selectApplicant(application, applicantId);
  return enquiries[applicant?.enquiryId || ''] || null;
};

export const selectAddressFor = (applicantId: string) => (state: RootState) => {
  const { application } = selectAvailableApplication(state);
  const applicant = selectApplicant(application, applicantId);
  return getApplicantAddress(application, applicant);
};

export const selectMedicalDetailsAddressFor = (applicantId: string) => (state: RootState) => {
  const { application } = selectAvailableApplication(state);
  const applicant = selectApplicant(application, applicantId);
  return getApplicantMedicalDetailsAddress(application, applicant);
};

export const selectHasDecision = (state: RootState) => {
  const { quoteDecision, preSalesDecisions } = selectAvailableApplication(state);
  return !!quoteDecision || Object.keys(preSalesDecisions).length > 0;
};

export const selectPreSalesDecisionResultFor = (applicantId: string) => (state: RootState) => {
  const { preSalesDecisions } = selectAvailableApplication(state);
  if (!preSalesDecisions) return null;
  return preSalesDecisions[applicantId] || null;
};

export const selectPreSalesDecisionsFor = (applicantId: string) => (state: RootState) => (
  selectPreSalesDecisionResultFor(applicantId)(state)?.preSalesDecisions || null);

export const selectApplicationStatus = (state: RootState) => {
  if (state.application.status !== 'available') {
    return null;
  }
  const { applicationStatus: { application: { status } }, enquiries } = selectAvailableApplication(state);
  return status === ApplicationStatus.Decision && Object.values(enquiries).some((e) => e.isOpen)
    ? ApplicationStatus.Apply
    : status ?? null;
};

export const selectApplicationExpiry = (state: RootState) => selectAvailableApplication(state).applicationStatus.application.expiryDate;

export const selectTotalPremium = (state: RootState): TotalPremium | null => {
  const { quoteDecision } = selectAvailableApplication(state);
  return quoteDecision?.plan?.totalPremium ?? null;
};

export const selectApplicantPremiumFor = (applicantId: string) => (state: RootState): ApplicantTotalPremium | null => {
  const { quoteDecision } = selectAvailableApplication(state);
  return quoteDecision?.applicants?.find((applicant) => applicant.id === applicantId)?.premiums ?? null;
};

export const selectApplicantDiscountFor = (applicantId: string) => (state: RootState): number | null => {
  const { quoteDecision } = selectAvailableApplication(state);
  return quoteDecision?.applicants?.find((applicant) => applicant.id === applicantId)?.applicantTotalDiscount ?? null;
};

export const selectProductQuoteDecision = (productId: string) => (state: RootState): ProductQuoteDecision | null => {
  const { quoteDecision } = selectAvailableApplication(state);
  return quoteDecision?.products?.find((product) => product.id === productId) ?? null;
};

export const selectThirdPartyRef = (state: RootState) => selectAvailableApplication(state).application.thirdPartyRef;

export const selectApplicationOrigin = (state: RootState) => selectAvailableApplication(state).application?.origin;

export const selectProductsStatus = (state: RootState) => selectAvailableApplication(state).productsStatus;

export const selectApplicationAdviserDetails = (state: RootState) => selectAvailableApplication(state).adviserDetails;

export const selectApplicationDates = (state: RootState) => selectAvailableApplication(state).application.dates;

export const selectApplicationLastReferredDate = (state: RootState) => selectAvailableApplication(state).application?.dates?.lastReferred ?? null;

export const selectPostUnderwritingDeclarationEnabledFor = (applicantId: string) => (state: RootState) => {
  const { applicationStatus } = selectAvailableApplication(state);
  const enquiry = selectEnquiryFor(applicantId)(state);
  const applicantStatus = getApplicantStatus(applicationStatus, applicantId);
  return isPostUnderwritingDeclarationEnabled(applicantStatus, enquiry);
};

export const selectAmraStatus = (applicantId: string) => (state: RootState) => {
  const { application, applicationStatus } = selectAvailableApplication(state);
  const applicant = selectApplicant(application, applicantId);
  return applicant ? getAmraProgressStatus(applicant, applicationStatus) : ProgressStatus.Incomplete;
};

export const selectApplicationBeneficiaries = (state: RootState) => selectAvailableApplication(state).application.beneficiaryDetails?.beneficiaries;

const NoErrors: ProductError[] = [];
export const selectProductsStatusErrors = (state: RootState) => {
  const { productsStatus } = selectAvailableApplication(state);
  if (productsStatus.status === 'invalid') {
    return productsStatus.errors;
  }
  return NoErrors;
};

export const selectBeneficiaryDetails = (state: RootState) => selectAvailableApplication(state).application.beneficiaryDetails;

export const selectView = (state: RootState) => selectAvailableApplication(state).view ?? emptyView;

function getNextView(state: ApplicationState, options?: ProceedOptions): View {
  if (state.status !== 'available') {
    return emptyView;
  }
  const target = options?.target || productView;
  if (options?.force) {
    return target;
  }
  const {
    application,
    enquiries,
    activeApplicantIndex,
    applicationStatus,
  } = state;
  const isPreSales = isPreSalesAppliction(application.status);
  const activeApplicant = getApplicantByIndex(application, activeApplicantIndex);

  if (target === newApplicantView || (activeApplicant && !isApplicantValid(application, activeApplicant))) {
    return newApplicantView;
  }
  if (target === lifestyleView || isApplicantMissingLifestyleAnswers(activeApplicant, enquiries)) {
    return lifestyleView;
  }
  if (!isPreSales && target.drawer?.type === 'condition') {
    if (isApplicantMissingContactDetails(application, activeApplicant)) {
      return contactDetailsView(target);
    }
    if (activeApplicant && !activeApplicant.hasCompletedPreUWDeclaration) {
      return preUnderwritingDeclarationView(target);
    }
  }
  if (target === postUnderwritingDeclarationView && (
    !activeApplicant || !isPostUnderwritingDeclarationEnabled(getApplicantStatus(applicationStatus, activeApplicant.id), enquiries[activeApplicant.enquiryId])
  )) {
    return productView;
  }
  return target;
}

export const proceed = (options?: ProceedOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const nextView = getNextView(selectApplicationState(getState()), options);
  dispatch(viewUpdated(nextView));
};

function getEmptyDecisions(): Decisions {
  return {
    preSalesDecisions: {},
    quoteDecision: null,
  };
}

async function loadApplicationDetails(application: ApplicationDto): Promise<AvailableApplication> {
  const applicationStatus = applicationApi.getApplicationStatus(application.id);
  const enquiries = enquiryApi.getAllFor(application.id, application.applicants, getEnquiryCollation(application));
  const adviserDetails = agencyApi.getAdviserDetails(application.id);
  const applicantIndex = toApplicantIndex(application.applicants.findIndex((a) => !isApplicantValid(application, a))) ?? 0;
  const state: AvailableApplication = {
    status: 'available',
    application,
    applicationStatus: await applicationStatus,
    enquiries: await enquiries,
    ...getEmptyDecisions(),
    productsStatus: {
      status: 'invalid',
      errors: [],
    },
    view: emptyView,
    activeApplicantIndex: applicantIndex,
    adviserDetails: await adviserDetails,
  };
  return {
    ...state,
    view: getNextView(state),
  };
}

export const addApplicant = (applicationId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const application = selectApplication(getState());
  const applicant = await applicationApi.addApplicant(applicationId);
  const [enquiry, applicationStatus] = await Promise.all([
    enquiryApi.getForApplicant(application.id, applicant.id, getEnquiryCollation(application)),
    applicationApi.getApplicationStatus(applicationId),
  ]);
  dispatch(applicantAdded(applicant));
  dispatch(enquiryUpdated(enquiry));
  dispatch(applicationStatusUpdated(applicationStatus));
  dispatch(applicantSelected(applicant.id));
};

export function patchApplicant(applicationId: string, applicantId: string, name: string, value: unknown): AppThunk<Promise<ApplicantDto[keyof ApplicantDto]>> {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const application = selectApplication(state);
    const op: Operation = {
      op: OperationType.Add,
      path: `/${name}`,
      value: asStringOrNull(value),
    };
    const newData = await applicationApi.patchApplicant(applicationId, applicantId, [op]);
    const enquiry = await enquiryApi.getForApplicant(applicationId, applicantId, getEnquiryCollation(application));
    const key = name as keyof ApplicantDto;
    const newValue = newData[key];
    dispatch(applicantPatched({ id: applicantId, property: key, value: newValue }));
    dispatch(enquiryUpdated(enquiry));
    return newValue;
  };
}

export const patchApplicantContactAddressField = (
  applicationId: string,
  applicantId: string,
  name: string,
  value: unknown,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const property = name as keyof AddressDto;
  const state = getState();
  const application = selectApplication(state);
  const addressId = selectApplicant(application, applicantId)?.contactDetails?.addressId;
  if (!addressId) {
    const savedAddress = await applicationApi.addAddress(applicationId, { [name]: value });
    await applicationApi.patchApplicant(applicationId, applicantId, [{
      op: OperationType.Add,
      path: '/contactDetails/addressId',
      value: savedAddress.id,
    }]);
    dispatch(addressAdded(savedAddress));
    dispatch(contactDetailsPatched({ applicantId, property: 'addressId', value: savedAddress.id }));
  } else {
    const savedAddress = await applicationApi.patchAddress(applicationId, addressId, [{
      op: OperationType.Add,
      path: `/${name}`,
      value,
    }]);
    const newValue = savedAddress[property];
    dispatch(addressPatched({ id: savedAddress.id!, property, value: newValue }));
  }
  return value as AddressDto[typeof property];
};

export const patchApplicantContactAddress = (
  applicationId: string,
  applicantId: string,
  changes: Partial<AddressDto>,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const addressId = selectApplicant(application, applicantId)?.contactDetails?.addressId;
  if (!addressId) {
    const savedAddress = await applicationApi.addAddress(applicationId, changes);
    const savedApplicant = await applicationApi.patchApplicant(applicationId, applicantId, [{
      op: OperationType.Add,
      path: '/contactDetails/addressId',
      value: savedAddress.id,
    }]);
    dispatch(addressAdded(savedAddress));
    dispatch(applicantUpdated(savedApplicant));
    return savedAddress;
  }
  const savedAddress = await applicationApi.patchAddress(applicationId, addressId, toPatchOperations(changes));
  dispatch(addressUpdated(savedAddress));
  return savedAddress;
};

export const deleteApplicant = (applicationId: string, applicantId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const productsStatus = selectProductsStatus(state);
  await applicationApi.deleteApplicant(applicationId, applicantId);
  const application = await applicationApi.getById(applicationId);
  const updatedState = await loadApplicationDetails(application);
  dispatch(updated({ ...updatedState, productsStatus }));
};

export const patchContactDetailsField = (
  applicationId: string,
  applicantId: string,
  name: string,
  value: unknown,
) => async (dispatch: AppDispatch) => {
  const savedApplicant = await applicationApi.patchApplicant(applicationId, applicantId, [
    {
      op: OperationType.Add,
      path: `/contactDetails/${name}`,
      value,
    },
  ]);
  const key = name as keyof ContactDetailsDto;
  const newValue = savedApplicant.contactDetails![key];
  dispatch(contactDetailsPatched({ applicantId, property: key, value: newValue }));
  return newValue;
};

export const patchBankDetails = (
  applicationId: string,
  changes: Partial<BankDetailsDto>,
) => async (dispatch: AppDispatch) => {
  const savedBankDetails = await applicationApi.patchBankDetails(applicationId, toPatchOperations(changes));
  dispatch(bankDetailsUpdated(savedBankDetails));
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const updateThirdPartyPayer = (
  applicationId: string,
  data: ThirdPartyPayerDto | null,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const { thirdPartyPayer } = selectApplication(getState());
  if (data !== null) {
    await applicationApi.updateThirdPartyPayer(applicationId, data);
    if (thirdPartyPayer?.addressId && data.addressId === null) {
      await applicationApi.deleteAddress(applicationId, thirdPartyPayer.addressId);
    }
  } else if (thirdPartyPayer) {
    await applicationApi.deleteThirdPartyPayer(applicationId);
    if (thirdPartyPayer?.addressId) {
      await applicationApi.deleteAddress(applicationId, thirdPartyPayer.addressId);
    }
  }
  dispatch(thirdPartyPayerUpdated(data));
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const patchThirdPartyPayerAddress = (changes: Partial<AddressDto>) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const application = selectApplication(getState());
  const addressId = application.thirdPartyPayer?.addressId;
  if (!addressId) {
    const savedAddress = await applicationApi.addAddress(application.id, changes);
    const modifiedThirdParty = {
      ...application.thirdPartyPayer,
      addressId: savedAddress.id,
    };
    await applicationApi.updateThirdPartyPayer(application.id, modifiedThirdParty);
    dispatch(addressAdded(savedAddress));
    dispatch(thirdPartyPayerUpdated(modifiedThirdParty));
    return savedAddress;
  }
  const savedAddress = await applicationApi.patchAddress(application.id, addressId, toPatchOperations(changes));
  dispatch(addressUpdated(savedAddress));
  return savedAddress;
};

export const uploadDirectDebitMandate = (applicationId: string, file: File) => async (dispatch: AppDispatch) => {
  const content = await readFileContent(file);
  const request: AddEvidenceDocumentDto = {
    applicationId,
    fileData: {
      fileContent: content,
      fileName: file.name,
      fileType: file.type,
      evidenceType: 'DD_MANDATE',
    },
  };
  await documentApi.uploadDirectDebitMandateDocument(request);
  const status = await applicationApi.getApplicationStatus(applicationId);
  const application = await applicationApi.getById(applicationId);
  dispatch(applicationStatusUpdated(status));
  dispatch(applicationUpdated(application));
};

export const patchApplicantMedicalDetailsAddress = (
  applicationId: string,
  applicantId: string,
  address: AddressDto,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const addressId = selectApplicant(application, applicantId)?.medicalDetails?.addressId;
  if (!addressId) {
    const savedAddress = await applicationApi.addAddress(applicationId, address);
    const savedApplicant = await applicationApi.patchApplicant(applicationId, applicantId, [{
      op: OperationType.Add,
      path: '/medicalDetails/addressId',
      value: savedAddress.id,
    }]);
    dispatch(addressAdded(savedAddress));
    dispatch(applicantUpdated(savedApplicant));
    return savedAddress;
  }
  const savedAddress = await applicationApi.patchAddress(applicationId, addressId, getAddressPatchOperations(address));
  dispatch(addressUpdated(savedAddress));
  return savedAddress;
};

export const patchAmraDeclarations = (
  applicationId: string,
  medicalDetails: Record<string, MedicalDetailsDto>,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const application = selectApplication(getState());
  const updatedApplicants: ApplicantDto[] = await Promise.all(Object.keys(medicalDetails).map((applicantId) => {
    const applicant = selectApplicant(application, applicantId);
    const updates = medicalDetails[applicantId];
    const ops = [
      {
        op: OperationType.Add,
        path: '/medicalDetails/previewRequired',
        value: updates.previewRequired,
      },
    ];
    if (updates.amraConsent && !applicant?.medicalDetails?.amraConsent) {
      ops.push({
        op: OperationType.Add,
        path: '/medicalDetails/amraConsent',
        value: updates.amraConsent,
      });
    }
    return applicationApi.patchApplicant(applicationId, applicantId, ops);
  }));
  // eslint-disable-next-line no-restricted-syntax
  for (const applicant of updatedApplicants) {
    dispatch(applicantUpdated(applicant));
  }
};

export const patchApplicantMedicalDetails = (
  applicationId: string,
  applicantId: string,
  medicalDetails: MedicalDetailsDto,
) => async (dispatch: AppDispatch) => {
  const savedApplicant = await applicationApi.patchApplicant(applicationId, applicantId, getAmraDoctorsPatchOperations(medicalDetails));
  dispatch(applicantUpdated(savedApplicant));
  return savedApplicant;
};

export const patchApplicantMarketingConcent = (
  applicationId: string,
  applicantId: string,
  marketingConsent: boolean | null,
) => async (dispatch: AppDispatch) => {
  const savedApplicant = await applicationApi.patchApplicant(applicationId, applicantId, [{ op: OperationType.Add, value: marketingConsent, path: '/marketingConsent' }]);
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicantUpdated(savedApplicant));
  dispatch(applicationStatusUpdated(applicationStatus));
  return savedApplicant;
};

export const sendEnquiryAnswer = (
  applicantId: string,
  name: string,
  value: unknown,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const collation = isPreSalesAppliction(application.status) ? EnquiryCollation.DepthFirst : undefined;
  const updatedEnquiry = await enquiryApi.postAnswers(application.id, applicantId, [{ question: name, answer: value }], collation);
  dispatch(enquiryUpdated(updatedEnquiry));
  return updatedEnquiry;
};

type ProductValidationMode = 'NoValidation' | 'ClientValidation';

async function getDecisions(application: ApplicationDto | null | undefined, validationMode: ProductValidationMode = 'ClientValidation'): Promise<Decisions> {
  const decisions = getEmptyDecisions();
  const isPreSales = application?.status === ApplicationStatus.PreSale;
  if (application
    && application.products.length > 0
    && (validationMode === 'NoValidation' || application.products.every((p) => isValid(isPreSales, p)))) {
    if (isPreSales) {
      decisions.preSalesDecisions = await decisionApi.getAllFor(application.id, application.applicants);
    } else {
      decisions.quoteDecision = await applicationApi.requestQuoteDecision(application.id);
    }
  }
  return decisions;
}

interface UpdateDecisionOptions {
  suppressErrors?: boolean;
  skipValidation?: boolean;
}

export const updateDecision = (options?: UpdateDecisionOptions) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status !== 'available') {
    return;
  }
  const application = selectApplication(state);
  try {
    const validationMode: ProductValidationMode = options?.skipValidation
      ? 'NoValidation'
      : 'ClientValidation';
    const decisions = await getDecisions(application, validationMode);
    const updatedApplication = await applicationApi.getById(application.id);
    dispatch(decisionsUpdated(decisions));
    dispatch(applicationUpdated(updatedApplication));
    dispatch(productsStatusUpdated(VALID));
  } catch (e: unknown) {
    dispatch(decisionsUpdated(getEmptyDecisions()));
    dispatch(productsStatusUpdated({ status: 'invalid', errors: toProductErrors(e) }));
    if (!options?.suppressErrors) {
      throw e;
    }
  }
};

export const requestFinalDecision = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available' && areAllEnquiriesClosed(state.application.enquiries)) {
    const application = selectApplication(state);
    const quoteDecision = await applicationApi.requestFinalDecision(application.id);
    const applicationStatus = await applicationApi.getApplicationStatus(application.id);
    const updatedApplication = await applicationApi.getById(application.id);
    dispatch(applicationUpdated(updatedApplication));
    dispatch(decisionsUpdated({
      ...getEmptyDecisions(),
      quoteDecision,
    }));
    dispatch(applicationStatusUpdated(applicationStatus));
  }
};

export const confirmPostDeclaration = (applicantId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const application = selectApplication(getState());
  const collation = isPreSalesAppliction(application.status) ? EnquiryCollation.DepthFirst : undefined;
  const enquiry = await enquiryApi.close(application.id, applicantId, collation);
  dispatch(enquiryUpdated(enquiry));
};

export const addProduct = (applicationId: string, product: ProductWithoutIdDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    const savedProduct = await applicationApi.addProduct(applicationId, product);
    const application = selectApplication(state);
    const applicants = application.applicants.filter((applicant) => savedProduct.applicantIds?.includes(applicant.id));
    const [enquiries, applicationStatus] = await Promise.all([
      enquiryApi.getAllFor(application.id, applicants, getEnquiryCollation(application)),
      applicationApi.getApplicationStatus(application.id),
    ]);
    dispatch(productAdded(savedProduct));
    dispatch(enquiriesUpdated(enquiries));
    dispatch(applicationStatusUpdated(applicationStatus));
    dispatch(decisionsUpdated(getEmptyDecisions()));
    dispatch(productStatusUpdated({
      productId: savedProduct.id,
      errors: [],
    }));
  }
  return selectApplicationState(getState());
};

export const validateProducts = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const application = selectApplication(getState());
  if (!application || application.products.length === 0) {
    return;
  }
  try {
    await applicationApi.getValidateProducts(application.id);
    dispatch(productsStatusUpdated(VALID));
  } catch (e: unknown) {
    dispatch(productsStatusUpdated({ status: 'invalid', errors: toProductErrors(e) }));
  }
};

export const patchProduct = (
  applicationId: string,
  productId: string,
  changes: Partial<ProductDto>,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status !== 'available') {
    return;
  }
  dispatch(decisionsUpdated(getEmptyDecisions()));
  const isPreSales = isPreSalesAppliction(selectApplicationStatus(state));
  try {
    dispatch(productsStatusUpdated(UPDATING));
    const newData = await applicationApi.patchProduct(applicationId, productId, toPatchOperations(changes));
    const application = selectApplication(state);
    const applicants = application.applicants.filter((applicant) => newData.applicantIds?.includes(applicant.id));
    const [enquiries, applicationStatus] = await Promise.all([
      enquiryApi.getAllFor(application.id, applicants, getEnquiryCollation(application)),
      applicationApi.getApplicationStatus(application.id),
    ]);
    dispatch(productUpdated(newData));
    dispatch(enquiriesUpdated(enquiries));
    dispatch(applicationStatusUpdated(applicationStatus));
    dispatch(productStatusUpdated({ productId, errors: [] }));
    if (!hasInvalidProduct(isPreSales, selectApplication(getState()))) {
      await applicationApi.getValidateProducts(applicationId);
      dispatch(productsStatusUpdated(VALID));
    }
  } catch (e: unknown) {
    dispatch(productStatusUpdated({
      productId,
      errors: toProductErrors(e, {
        id: productId,
        type: ProductErrorType.Product,
      }),
    }));
  }
};

export const patchProductStartDates = (
  applicationId: string,
  products: ProductDto[],
  startDate: string | null,
) => async (dispatch: AppDispatch) => {
  const op: Operation = {
    op: OperationType.Add,
    path: '/startDate',
    value: startDate,
  };
  // eslint-disable-next-line no-restricted-syntax
  for (const product of products) {
    // eslint-disable-next-line no-await-in-loop
    const updatedProduct = await applicationApi.patchProduct(applicationId, product.id, [op]);
    dispatch(productUpdated(updatedProduct));
  }
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const patchBeneficiaryNominationNomination = (
  applicationId: string,
  productId: string,
  beneficiaryNomination: boolean | null,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status !== 'available') {
    return;
  }
  const op: Operation = {
    op: OperationType.Add,
    path: '/beneficiaryNomination',
    value: beneficiaryNomination,
  };
  const updatedProduct = await applicationApi.patchProduct(applicationId, productId, [op]);
  dispatch(productUpdated(updatedProduct));
};

export const patchApplicationAdvisedSale = (applicationId: string, value: boolean | null) => async (dispatch: AppDispatch) => {
  const op: Operation = {
    op: OperationType.Add,
    path: '/isAdvisedSale',
    value,
  };
  await applicationApi.patchApplication(applicationId, [op]);
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicationPatched({ property: 'isAdvisedSale', value }));
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const patchApplicationAdviserDeclaration = (applicationId: string, value: boolean | null) => async (dispatch: AppDispatch) => {
  const op: Operation = {
    op: OperationType.Add,
    path: '/adviserDeclaration',
    value,
  };
  await applicationApi.patchApplication(applicationId, [op]);
  const applicationStatus = await applicationApi.getApplicationStatus(applicationId);
  dispatch(applicationPatched({ property: 'adviserDeclaration', value }));
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const getApplicationStatus = () => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const applicationStatus = await applicationApi.getApplicationStatus(application.id);
  dispatch(applicationStatusUpdated(applicationStatus));
};

export const deleteProduct = (applicationId: string, product: ProductDto) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    await applicationApi.deleteProduct(applicationId, product.id);
    const application = selectApplication(state);
    const applicants = application.applicants.filter((applicant) => product.applicantIds?.includes(applicant.id));
    const enquiries = await enquiryApi.getAllFor(application.id, applicants, getEnquiryCollation(application));
    dispatch(productDeleted(product));
    dispatch(enquiriesUpdated(enquiries));
    dispatch(updateDecision());
    dispatch(productStatusUpdated({
      productId: product.id,
      errors: [],
    }));
  }
};

export const addDisclosure = (applicantId: string, enquiry: Enquiry) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const disclosureId = crypto.randomUUID();
  const updatedAnswer = toggleAnswer(disclosureId, getMultipleAnswer(enquiry.allAnswers?.[presalesDisclosuresPath]));
  const updatedEnquiry = await enquiryApi.postAnswers(
    application.id,
    applicantId,
    [{ question: presalesDisclosuresPath, answer: updatedAnswer }],
    getEnquiryCollation(application),
  );
  dispatch(enquiryUpdated(updatedEnquiry));
  return disclosureId;
};

export const removeDisclosure = (applicantId: string, enquiry: Enquiry, disclosureId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const currentAnswer = getMultipleAnswer(enquiry.allAnswers?.[presalesDisclosuresPath]);
  if (currentAnswer.includes(disclosureId)) {
    const state = getState();
    const application = selectApplication(state);
    const updatedAnswer = removeAnswer(disclosureId, currentAnswer);
    const updatedEnquiry = await enquiryApi.postAnswers(
      application.id,
      applicantId,
      [{ question: presalesDisclosuresPath, answer: updatedAnswer }],
      getEnquiryCollation(application),
    );
    dispatch(enquiryUpdated(updatedEnquiry));
  }
};

export const removeConditon = (
  applicantId: string,
  enquiry: Enquiry,
  enquiryLine: EnquiryLine,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  const application = selectApplication(state);
  const updates = getAnswersToRemoveCondition(enquiry, enquiryLine);
  const updatedEnquiry = await enquiryApi.postAnswers(application.id, applicantId, updates, getEnquiryCollation(application));
  dispatch(enquiryUpdated(updatedEnquiry));
};

export const putThirdPartyRef = (applicationId: string, thirdPartyRef: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    await applicationApi.putThirdPartyRef(applicationId, thirdPartyRef);
    dispatch(thirdPartyRefUpdated(thirdPartyRef));
  }
};

async function loadDecision(availableApplication: AvailableApplication, dispatch: AppDispatch) {
  const { application } = availableApplication;
  if (application.products.length > 0) {
    if (application.status === ApplicationStatus.Apply && areAllEnquiriesClosed(availableApplication.enquiries)) {
      await dispatch(requestFinalDecision());
    } else {
      await dispatch(updateDecision({ suppressErrors: true }));
    }
  }
}

export const loadApplication = (id: string) => async (dispatch: AppDispatch) => {
  dispatch(updated(LOADING));
  try {
    const application = await applicationApi.getById(id);
    const availableApplication = await loadApplicationDetails(application);
    dispatch(updated(availableApplication));
    await loadDecision(availableApplication, dispatch);
  } catch (e) {
    dispatch(updated(NONE));
    const message = isAxiosError(e) && e.response?.status === 401
      ? 'Unauthorised to load application'
      : 'Failed to load application';
    throw new Error(message);
  }
};

export const startApplication = (enquiryType: UnderwriteMeEnquiryType, agentId?: string) => async (dispatch: AppDispatch) => {
  dispatch(updated(LOADING));
  try {
    const application = await applicationApi.create(enquiryType, agentId);
    dispatch(updated(NONE));
    return application;
  } finally {
    dispatch(updated(NONE));
  }
};

export const clearApplication = () => (dispatch: AppDispatch) => {
  dispatch(updated(NONE));
};

export const convertApplicationToFull = (applicationId: string) => async (dispatch: AppDispatch) => {
  const application = await applicationApi.convertToFull(applicationId);
  const availableApplication = await loadApplicationDetails(application);
  dispatch(updated(availableApplication));
};

export const updateApplicationOwner = (applicationId: string, agencyId: string) => async (dispatch: AppDispatch) => {
  const application = await applicationApi.updateOwner(applicationId, agencyId);
  const availableApplication = await loadApplicationDetails(application);
  dispatch(updated(availableApplication));
  await loadDecision(availableApplication, dispatch);
};

export const updateQuoteEffectiveDate = (applicationId: string, quoteEffectiveDate: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    await applicationApi.updateQuoteEffectiveDate(applicationId, quoteEffectiveDate);
    dispatch(quoteEffectiveDateUpdated(quoteEffectiveDate));
    await loadDecision(selectAvailableApplication(getState()), dispatch);
  }
};

export const updateCommissionTerms = (
  terms: UpdateApplicationAgencyCommissionTermsDto,
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    dispatch(commissionTermsUpdated(terms));
    const { id } = selectApplication(state);
    await applicationApi.updateCommissionTerms(id, terms);
    const adviserDetails = await agencyApi.getAdviserDetails(id);
    await loadDecision(selectAvailableApplication(getState()), dispatch);
    // Allow product commission changes to be reflected on the UI before updating options
    setTimeout(() => dispatch(adviserDetailsUpdated(adviserDetails)));
  }
};

export const getApplicationAfterComplete = (applicationId: string) => async (dispatch: AppDispatch) => {
  const application = await applicationApi.getById(applicationId);
  dispatch(applicationPatched({ property: 'applicants', value: application.applicants }));
  dispatch(applicationPatched({ property: 'products', value: application.products }));
  const response = await applicationApi.getApplicationStatus(application.id, true);
  dispatch(applicationStatusUpdated(response));
};

export const addBeneficiary = (applicationId: string, beneficiary: BeneficiaryDto) => async (dispatch: AppDispatch) => {
  const newBeneficiary = await beneficiaryApi.addBeneficiary(applicationId, beneficiary);
  dispatch(beneficiaryAdded(newBeneficiary));
};

export const deleteBeneficiary = (applicationId: string, beneficiary: BeneficiaryDto) => async (dispatch: AppDispatch) => {
  if (beneficiary.id) {
    await beneficiaryApi.deleteBeneficiary(applicationId, beneficiary.id);
    dispatch(beneficiaryDeleted(beneficiary));
  }
};

export const updateBeneficiary = (applicationId: string, beneficiary: BeneficiaryDto) => async (dispatch: AppDispatch) => {
  await beneficiaryApi.updateBeneficiary(applicationId, beneficiary);
  dispatch(beneficiaryUpdated(beneficiary));
};

export const updateBeneficiaries = (beneficiaries: BeneficiaryDto[]) => async (dispatch: AppDispatch) => {
  dispatch(beneficiariesUpdated(beneficiaries));
};

export const updateBeneficiaryNomination = (
  productId: string,
  beneficiaries: BeneficiaryNominationDto[],
) => async (dispatch: AppDispatch, getState: () => RootState) => {
  const state = getState();
  if (state.application.status === 'available') {
    dispatch(beneficiaryNominationUpdated({ productId, beneficiaries }));
  }
};
