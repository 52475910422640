import { alpha, styled } from '@mui/material/styles';
import MuiButtonGroup from '@mui/material/ButtonGroup';
import MuiInputBase from '@mui/material/InputBase';
import MuiRadioGroup from '@mui/material/RadioGroup';
import { FormGroup } from '@mui/material';

export const InputBase = styled(MuiInputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      WebkitAppearance: 'none',
      margin: 0,
    },
    '&[type=number]': {
      MozAppearance: 'textfield',
    },
  },
}));

export const RadioGroup = styled(MuiRadioGroup)(({ theme, row }) => ({
  flexDirection: row ? 'row' : 'column',
  gap: row ? 0 : theme.spacing(1),
  '& > label': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.surface9,
    paddingRight: 9,
    marginLeft: 0,
    '&:has(.Mui-checked)': {
      backgroundColor: theme.palette.common.greenLight,
    },
  },
}));

export const CheckboxGroup = styled(FormGroup)(({ theme, row }) => ({
  gap: row ? 0 : theme.spacing(1),
  '& > label': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.surface9,
    paddingRight: 9,
    marginLeft: 0,
    '&:has(.Mui-checked)': {
      backgroundColor: theme.palette.common.greenLight,
    },
    '& > span:last-of-type': {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

export const ButtonGroup = styled(MuiButtonGroup)(({ theme }) => ({
  '& > button': {
    borderWidth: 1,
    borderColor: theme.palette.common.surface3,
    color: theme.palette.text.primary,
    height: 45,
    ...theme.typography.body1,
    '&:hover': {
      borderWidth: 1,
      backgroundColor: alpha(theme.palette.secondary.main, 0.75),
      borderColor: theme.palette.common.surface3,
      color: theme.palette.common.white,
    },
    '&.MuiButton-containedSecondary': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.common.secondary800,
    },
  },
}));
