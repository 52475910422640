import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { Card, CardContent, CardProps } from '@mui/material';
import { DateField } from '../../../../components/form/fields';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { asClientDate, parseServerDate, todayDate } from '../../../../utils/converters';
import { patchProductStartDates, selectApplicationDates } from '../../../../features/application-slice';
import { isValidPolicyStartDate } from '../../../../services/application-helpers';
import { handleError } from '../../../../components/form/form.utils';
import { FormContainer } from '../../../../components/form';
import { ProductDto } from '../../../../services/models/product-dto';

interface FormData {
  startDate: string | null;
}

function validationSchema(maxDate: string | null): yup.ObjectSchema<FormData> {
  return yup.object({
    startDate: yup.string()
      .required('A valid start date is required')
      .test(
        'minDate',
        'The start date cannot be in the past',
        (value) => parseServerDate(value)?.isSameOrAfter(moment().startOf('day')),
      )
      .test(
        'maxDate',
        `The latest allowed start date is ${asClientDate(maxDate)}`,
        (value) => isValidPolicyStartDate(value, maxDate),
      ),
  });
}

interface CoverStartDateProps extends CardProps {
  applicationId: string;
  products: ProductDto[];
}

function CoverStartDate({ applicationId, products, ...props }: CoverStartDateProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const applicationDates = useAppSelector(selectApplicationDates);
  const initialStartDate = products[0].startDate;
  const [initialised, setInitialised] = useState(!initialStartDate);
  const formMethods = useForm<FormData>({
    defaultValues: { startDate: initialStartDate },
    resolver: yupResolver(validationSchema(applicationDates?.decisionExpiry ?? null)),
    mode: 'onBlur',
  });
  const {
    clearErrors,
    resetField,
    setError,
    setValue,
    trigger,
  } = formMethods;

  const handleStartDateChange = async (name: string, value: string | null) => {
    clearErrors('startDate');
    try {
      if (await trigger()) {
        await dispatch(patchProductStartDates(applicationId, products, value));
        resetField('startDate', { defaultValue: value });
        setValue('startDate', value);
      }
    } catch (e) {
      handleError(e, (message) => {
        setError('startDate', { message });
      });
    }
  };

  const init = async () => {
    await trigger();
    if (initialStartDate && products.filter((p) => !p.startDate).length > 0 && parseServerDate(initialStartDate)?.isSameOrAfter(todayDate().startOf('day'))) {
      handleStartDateChange('startDate', initialStartDate);
    }
    setInitialised(true);
  };

  useEffect(() => {
    if (!initialised) {
      init();
    }
  }, [initialised]);

  return (
    <Card {...props}>
      <CardContent>
        <FormProvider {...formMethods}>
          <FormContainer>
            <DateField
              name="startDate"
              label={t('components.policyStartDate.label')}
              onChangeCommitted={handleStartDateChange}
              disablePast
              maxDate={parseServerDate(applicationDates?.decisionExpiry) ?? undefined}
              sx={{ width: '100%' }}
              disabled={!initialised}
            />
          </FormContainer>
        </FormProvider>
      </CardContent>
    </Card>
  );
}

export default CoverStartDate;
