import { Box, Chip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const QuestionStatus = styled(Box)(() => ({
  width: 150,
  flex: '0 0 auto',
  alignSelf: 'flex-start',
}));

export const QuestionStatisfied = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.common.greenLight,
  float: 'right',
}));

export const QuestionErrorContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  flex: '0 0 100%',
}));

export default QuestionErrorContainer;
