import { Alert, styled } from '@mui/material';

export const InfoAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface8,
  color: theme.palette.text.primary,
  '& svg': {
    color: theme.palette.secondary.main,
  },
}));

export default {};
