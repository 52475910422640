import React from 'react';
import { Breadcrumbs } from '@mui/material';
import { EnquiryLine } from '../../../services/models/enquiry-line';

interface EnquiryLineBreadcrumbsProps {
  enquiryLine: EnquiryLine;
  applicantName?: string | null;
}

function EnquiryLineBreadcrumbs({ enquiryLine, applicantName = undefined }: EnquiryLineBreadcrumbsProps) {
  return (
    <Breadcrumbs separator=">">
      {applicantName && <span>{applicantName}</span>}
      <span>{enquiryLine.section}</span>
      <span>{enquiryLine.alias}</span>
    </Breadcrumbs>
  );
}

export default EnquiryLineBreadcrumbs;
