import {
  AccordionSummary,
  CardContent,
  Stack,
  styled,
} from '@mui/material';
import { ProductQuoteDecisonTable } from '../decision-table';
import { shouldForwardProp } from '../../../../components/types';
import { DecisionContainerProps, getDecisionColor } from '../decision-table/decision-table.styles';

export const Guidance = styled(Stack)(({ theme }) => ({
  'h3:first-of-type > svg': {
    verticalAlign: 'text-bottom',
    marginRight: theme.spacing(1),
  },
  'h3:nth-of-type(2)': {
    marginLeft: theme.spacing(4.5),
    ...theme.typography.h3,
    fontFamily: theme.typography.body1.fontFamily,
  },
}));

export const DecisionCardRow = styled(CardContent, {
  shouldForwardProp: (prop) => shouldForwardProp<DecisionContainerProps>(['decision'], prop),
})<DecisionContainerProps>(({ theme, decision }) => ({
  backgroundColor: getDecisionColor(decision, theme),
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

export const DecisionDetailsAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  borderBottom: `1px solid ${theme.palette.common.secondary200}`,
}));

export const ProductQuoteDecisionTableStyled = styled(ProductQuoteDecisonTable)(({ theme }) => ({
  marginTop: theme.spacing(-1),
  marginBottom: 0,
  marginLeft: 0,
  marginRight: 0,
}));

export default {};
