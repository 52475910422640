import { styled } from '@mui/material/styles';
import { Alert, Box, Typography } from '@mui/material';
import { FormContainer } from '../../../../components/form';
import TickIcon from '../../../../assets/icons/tick-icon.svg';
import { DrawerCardContent } from '../../../../components/drawer-card';

export const FormContainerStyled = styled(FormContainer)(({ theme }) => ({
  'label:has(.MuiCheckbox-root)': {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.common.surface8,
    paddingRight: 9,
    marginLeft: 0,
    '&:has(.Mui-checked)': {
      backgroundColor: theme.palette.common.greenLight,
    },
  },
  section: {
    marginBottom: theme.spacing(6),
    li: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export const AmraTitle = styled(Typography)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  fontSize: 24,
  paddingBottom: theme.spacing(2),
  marginBottom: theme.spacing(3),
}));

export const StyledList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingLeft: theme.spacing(3),
  '& > li': {
    position: 'relative',
    marginBottom: theme.spacing(1),
    '&:before': {
      content: "''",
      width: 11,
      height: 11,
      borderRadius: '50%',
      backgroundColor: theme.palette.secondary.main,
      position: 'absolute',
      top: 5,
      left: -20,
    },
  },
}));

export const ConsentCardContent = styled(DrawerCardContent)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface10,
  paddingBottom: 0,
  overflowY: 'scroll',
}));

export const TickList = styled('ul')(({ theme }) => ({
  listStyle: 'none',
  paddingLeft: theme.spacing(4.5),
  '& > li': {
    position: 'relative',
    marginBottom: theme.spacing(1),
    '&:before': {
      content: "''",
      width: 20,
      height: 20,
      borderRadius: '50%',
      background: `url(${TickIcon}) no-repeat`,
      position: 'absolute',
      top: 1,
      left: -32,
    },
  },
}));

export const ConsentForm = styled(Box)(({ theme }) => ({
  '& h3 > span': {
    fontFamily: theme.typography.h6.fontFamily,
    marginLeft: theme.spacing(1),
  },
  '& label': {
    fontFamily: theme.typography.body1.fontFamily,
  },
  '& label:has(.MuiCheckbox-root)': {
    backgroundColor: theme.palette.common.primary200,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(3),
  },
}));

export const AmraAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.common.surface8,
  color: theme.palette.text.primary,
  '& svg': {
    color: theme.palette.secondary.main,
  },
}));

export default FormContainerStyled;
