import React from 'react';
import {
  Card,
  CardContent,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ApplicationDto } from '../../../../services/models/application-dto';
import MarketingConsentForm from './marketing-consent-form';
import { CommsList } from './marketing-comms-panel.styles';
import useBreakpoint from '../../../../hooks/use-breakpoints';

export interface MarketingCommsPanelProps {
  application: ApplicationDto
}

function MarketingCommsPanel({ application }: MarketingCommsPanelProps) {
  const { t } = useTranslation();
  const isWideScreen = useBreakpoint('md');

  return (
    <Card sx={{ marginBottom: 3 }}>
      <CardContent>
        <Typography><strong>{t('components.marketingCommsPanel.title')}</strong></Typography>
        <CommsList>
          {(t('components.marketingCommsPanel.items', { returnObjects: true }) as string[]).map((item) => <li key={item}>{item}</li>)}
        </CommsList>
        <Typography>{t('components.marketingCommsPanel.footer')}</Typography>
        <Stack
          direction={isWideScreen ? 'row' : 'column'}
          gap={isWideScreen ? 16 : 2}
          sx={{ mt: 3, width: application.applicants.length === 1 ? '50%' : '100%' }}
        >
          {application.applicants.map((applicant) => (
            <MarketingConsentForm
              key={applicant.id}
              applicationId={application.id}
              applicant={applicant}
              sx={{ flex: '50%' }}
            />
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default MarketingCommsPanel;
