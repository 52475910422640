import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  convertApplicationToFull,
  selectActiveApplicant,
  selectApplicationStatus,
  selectAvailableApplication,
  selectView,
  updateDecision,
} from '../../features/application-slice';
import {
  selectAvailableSettings,
  selectOnboardingToolSettings,
  selectPresalesToolSettings,
} from '../../features/settings-slice';
import ApplicationLayout from './components/application-layout/application-layout';
import OnboardingDisclosuresPanel from './components/disclosures-panel';
import ProductsPanel from './components/products-panel';
import {
  amraDeclarationView,
  conditionView,
  postUnderwritingDeclarationView,
} from '../view';
import useProceed from '../../hooks/use-proceed';
import { EnquiryLine } from '../../services/models/enquiry-line';
import { ColumnStack, SidebarStack } from './application.styles';
import { hasApplicantPreSalesDecision } from '../../services/decision-helpers';
import {
  firstValidProduct,
  getApplicationDocuments,
  getPreSaleToolProductCodes,
  hasIPProduct,
  hasInvalidProduct,
  isApplicationReadOnly,
  isPreSalesAppliction,
  isUMEApplication,
} from '../../services/application-helpers';
import { AvailableProductDefinition } from '../../services/models/available-product-definition';
import InfoBarContent from './components/info-bar-content';
import ApplicationDocumentsPanel from './components/application-documents-panel';
import DrawerContent from './components/drawer-content';
import { Footer } from '../../components/layout/footer';
import ActionFooter from './components/action-footer';
import UnderwritingStatus from './components/underwriting-status';
import { ApplicationStatus } from '../../services/models/application-status';
import BothClientsProducts from './components/both-clients-products/both-clients-products';
import { getProductsForApplicant, ProductEditability } from '../../services/product-helpers';
import PreSalesDisclosuresPanel from './components/disclosures-panel/presales-disclosures-panel';
import { isCollegue } from '../../services/auth-api';
import { ApplicationDto } from '../../services/models/application-dto';
import { ProductType } from '../../services/models/product-type';
import { ApplicantDto } from '../../services/models/applicant-dto';
import analytics from '../../services/analytics';
import { AssignApplicationPopup } from './components/assign-application';

function useAvailableProductDefinitions(application: ApplicationDto, applicant: ApplicantDto | null): AvailableProductDefinition[] {
  const settings = useAppSelector(selectAvailableSettings);
  const preSalesToolSettings = useAppSelector(selectPresalesToolSettings);
  const onboardingToolSettings = useAppSelector(selectOnboardingToolSettings);
  const isPreSales = isPreSalesAppliction(useAppSelector(selectApplicationStatus));
  if (!applicant) {
    return [];
  }
  const productCodes = isPreSales
    ? getPreSaleToolProductCodes(preSalesToolSettings, isCollegue())
    : onboardingToolSettings?.productCodes?.codes ?? [];
  const availableDefinitions: AvailableProductDefinition[] = settings.productDefinitions
    .map((definition) => ({
      ...definition,
      applicants: [applicant],
      available: productCodes.includes(definition.productCode)
        && (!isPreSales || !application.products.some((p) => p.code === definition.productCode)),
    })).flatMap((definition) => (application.applicants.length > 1 && definition.productType === ProductType.ImpairedLife
      ? [definition, { ...definition, name: `${definition.name} (joint life)`, applicants: application.applicants }]
      : [definition]));
  return availableDefinitions;
}

function getProductsEditability(application: ApplicationDto): ProductEditability {
  if (isApplicationReadOnly(application)) {
    return ProductEditability.ReadOnly;
  }
  if (isUMEApplication(application.origin)) {
    return ProductEditability.CoverAmountOnly;
  }
  return ProductEditability.Editable;
}

function Application() {
  const state = useAppSelector(selectAvailableApplication);
  const applicant = useAppSelector(selectActiveApplicant);
  const dispatch = useAppDispatch();
  const settings = useAppSelector(selectAvailableSettings);
  const view = useAppSelector(selectView);
  const proceedToNextView = useProceed();
  const {
    application,
    applicationStatus,
    enquiries,
    preSalesDecisions,
    quoteDecision,
  } = state;
  const status = useAppSelector(selectApplicationStatus);
  const { products } = application;
  const applicantProducts = applicant ? getProductsForApplicant(products, applicant.id) : [];
  const isPreSales = isPreSalesAppliction(status);
  const productsEditable = getProductsEditability(application);
  const readOnly = isApplicationReadOnly(application);
  const applicationExpiry = applicationStatus.application.expiryDate;
  const availableProducts = useAvailableProductDefinitions(application, applicant);

  const handleEditCondition = async (condition: EnquiryLine) => {
    proceedToNextView({ target: conditionView(condition.path!) });
  };

  const handleEditAmra = async () => {
    proceedToNextView({ target: amraDeclarationView });
  };

  const handleEditUnderwritingDeclaration = () => {
    proceedToNextView({ target: postUnderwritingDeclarationView });
  };

  const handleDisclosureChange = async () => {
    await dispatch(updateDecision({ skipValidation: true, suppressErrors: true }));
  };

  const handleConvertToFullApplication = async () => {
    await dispatch(convertApplicationToFull(application.id));
    analytics.trackConvertToApplication(application.id);
    proceedToNextView();
  };

  const getFooter = () => {
    if (isPreSalesAppliction(state.application.status)) {
      return <Footer />;
    }
    if (products.length > 0) {
      return <ActionFooter />;
    }
    return null;
  };

  return (
    <>
      <AssignApplicationPopup />
      <ApplicationLayout
        info={(
          <InfoBarContent
            isPreSales={isPreSales}
            proceedToNextView={proceedToNextView}
            readonly={readOnly}
          />
        )}
        drawer={(
          <DrawerContent
            key={applicant?.id}
            view={view}
            application={application}
            applicant={applicant}
            proceedToNextView={proceedToNextView}
          />
        )}
        drawerOpen={!!view.drawer}
        footer={getFooter()}
      >
        {view.mainContent === 'products' && applicant && (
          <>
            {status === ApplicationStatus.Underwriting && <UnderwritingStatus applicationId={application.id} sx={{ flex: '0 0 100%' }} />}
            <ColumnStack direction="row" justifyContent="space-around">
              <ProductsPanel
                applicant={applicant}
                availableProducts={availableProducts}
                products={applicantProducts}
                applicationExpiry={applicationExpiry}
                onChange={proceedToNextView}
                onConvertToFullApplication={handleConvertToFullApplication}
                editable={productsEditable}
              />
              <SidebarStack gap={4}>
                {isPreSales && (
                  <PreSalesDisclosuresPanel
                    applicantId={applicant.id}
                    enquiry={enquiries[applicant.enquiryId]}
                    showDisclosures={
                      hasApplicantPreSalesDecision(applicant.id, preSalesDecisions)
                      && (!!firstValidProduct(isPreSales, application) || hasIPProduct(application))
                    }
                    onEdit={handleEditCondition}
                    onChange={handleDisclosureChange}
                  />
                )}
                {!isPreSales && (
                  <>
                    {quoteDecision && (
                      <OnboardingDisclosuresPanel
                        applicantId={applicant.id}
                        applicationStatus={status}
                        enquiry={enquiries[applicant.enquiryId]}
                        showDisclosures={!!firstValidProduct(isPreSales, application) || !!quoteDecision}
                        showApplyHeader={!applicant.hasCompletedPreUWDeclaration}
                        disabled={readOnly || !quoteDecision || hasInvalidProduct(isPreSales, application)}
                        onEdit={handleEditCondition}
                        onEditAmra={handleEditAmra}
                        onEditUnderwritingDeclaration={handleEditUnderwritingDeclaration}
                      />
                    )}
                    <ApplicationDocumentsPanel
                      applicationId={application.id}
                      applicant={applicant}
                      quoteDecision={quoteDecision}
                      applicationStatus={applicationStatus.application.status}
                      documents={getApplicationDocuments(applicantProducts, settings.productDefinitions)}
                    />
                  </>
                )}

              </SidebarStack>
            </ColumnStack>
          </>
        )}
        {view.mainContent === 'products' && !applicant && (
          <BothClientsProducts
            application={application}
            quoteDecision={quoteDecision}
            applicationExpiry={applicationExpiry}
          />
        )}
      </ApplicationLayout>
    </>
  );
}

export default Application;
