import React from 'react';
import { LoadingButton } from '@mui/lab';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { FooterBar } from '../../../../components/layout/footer';
import { ReviewPage } from '../../../pages';
import { useAppSelector } from '../../../../store/hooks';
import { selectAvailableApplication } from '../../../../features/application-slice';
import {
  ApplicationStatusSectionName,
  isApplicationBeneficiariesComplete,
  isApplicationMarketingConsentComplete,
  isApplicationSectionComplete,
} from '../../../../services/application-helpers';

interface PolicySetupFooterProps {
  applicationId: string;
}

function PolicySetupFooter({ applicationId }: PolicySetupFooterProps) {
  const { t } = useTranslation();
  const { applicationStatus, application, quoteDecision } = useAppSelector(selectAvailableApplication);
  const navigate = useNavigate();
  const handleStartCover = () => navigate(generatePath(ReviewPage.path, { applicationId }));
  const startCoverDisabled = !isApplicationSectionComplete(ApplicationStatusSectionName.PaymentDetails, applicationStatus)
    || !isApplicationSectionComplete(ApplicationStatusSectionName.PolicySetup, applicationStatus)
    || !isApplicationMarketingConsentComplete(application)
    || (!!quoteDecision && !isApplicationBeneficiariesComplete(application, quoteDecision));
  return (
    <FooterBar>
      <LoadingButton
        color="primary"
        variant="contained"
        onClick={handleStartCover}
        endIcon={<ArrowForwardIcon />}
        disabled={startCoverDisabled}
      >
        {t('components.policySetupFooter.startCover')}
      </LoadingButton>
    </FooterBar>
  );
}

export default PolicySetupFooter;
