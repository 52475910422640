import React from 'react';
import * as yup from 'yup';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Divider,
  Stack,
  StackProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { booleanToYesNo, handleError, yesNoToBoolean } from '../../../../components/form/form.utils';
import { ApplicantDto } from '../../../../services/models/applicant-dto';
import { getApplicantName } from '../../../../services/application-helpers';
import { RadioOptionsField } from '../../../../components/form/fields';
import { InfoAlert } from './marketing-consent-form.styles';
import { patchApplicantMarketingConcent } from '../../../../features/application-slice';
import { useAppDispatch } from '../../../../store/hooks';

const validationSchema = yup.object({
  advice: yup.boolean().required('Required'),
});

export interface MarketingConsentFormProps extends StackProps {
  applicationId: string;
  applicant: ApplicantDto;
}

function MarketingConsentForm({ applicationId, applicant, ...props }: MarketingConsentFormProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { marketingConsent } = applicant;

  const formMethods = useForm({
    defaultValues: {
      marketingConsent: booleanToYesNo(marketingConsent),
    },
    resolver: yupResolver(validationSchema) as any,
  });
  const {
    clearErrors,
    setError,
    getValues,
    watch,
  } = formMethods;

  watch('marketingConsent');

  const handleChange = async (name: string, value: string | null) => {
    try {
      await dispatch(patchApplicantMarketingConcent(applicationId, applicant.id, yesNoToBoolean(value)));
    } catch (e) {
      handleError(e, (message) => {
        clearErrors('marketingConsent');
        setError('marketingConsent', { message });
      });
    }
  };

  return (
    <Stack gap={2} {...props}>
      <FormProvider {...formMethods}>
        <Typography variant="h6" component="h3">{t('components.marketingConsentForm.title', { applicantName: getApplicantName(applicant) })}</Typography>
        <Divider />
        <RadioOptionsField
          name="marketingConsent"
          options={['yes', 'no']}
          sx={{
            minWidth: 200,
          }}
          labelTranslationBasePath="common.yesNo"
          onChangeCommitted={handleChange}
          hideError
        />
        <InfoAlert severity="info">{getValues().marketingConsent === 'no' ? t('components.marketingConsentForm.optOut') : t('components.marketingConsentForm.optIn')}</InfoAlert>
      </FormProvider>
    </Stack>
  );
}

export default MarketingConsentForm;
