import React from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface DurationProps extends TypographyProps {
  value: number | null | undefined;
  noSpace?: boolean;
  durationText?: string | undefined;
}

function Duration({
  value,
  noSpace = undefined,
  durationText = undefined,
  ...props
}: DurationProps) {
  const { t } = useTranslation();
  if (!value) {
    return null;
  }
  return (
    <Typography variant="body2" component="span" sx={{ display: 'block' }} {...props}>
      {`${noSpace ? '' : ' '}${durationText ?? t('common.duration.for')}`}
      <b>
        {value}
        {t('common.duration.months')}
      </b>
    </Typography>
  );
}

export default Duration;
