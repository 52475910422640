import Box from '@mui/material/Box';
import TableContainer from '@mui/material/TableContainer';
import MuiTableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import { shouldForwardProp } from '../../../../components/types';
import ProductQuoteDecisionAccordion from '../decision-table/product-quote-decision-accordion';

type TableRowDarkProps = {
  last?: boolean;
};

export const RowLabelWidth = 250;

export const TableContainerStyled = styled(TableContainer)(({ theme }) => ({
  width: '100%',
  maxWidth: '100%',
  position: 'relative',
  overflowX: 'hidden',
  '& table': {
    position: 'relative',
    '& th': {
      borderBottom: `1px solid ${theme.palette.common.secondary200}`,
      borderLeft: `1px solid ${theme.palette.common.secondary200}`,
      borderRight: `1px solid ${theme.palette.common.secondary200}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(4),
      ...theme.typography.h4,
      '&:before': {
        content: '""',
        position: 'absolute',
        left: -1,
        top: 0,
        bottom: 0,
        width: 1,
        background: theme.palette.common.white,
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        right: -1,
        top: 0,
        bottom: 0,
        width: 1,
        background: theme.palette.common.white,
      },
    },
    '& > thead > tr > td, th': {
      backgroundColor: theme.palette.common.secondary,
      color: theme.palette.common.white,
      ...theme.typography.body1,
      textAlign: 'center',
    },
    '& > tbody > tr > td': {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      verticalAlign: 'top',
      borderRight: `1px solid ${theme.palette.common.secondary200}`,
    },
  },
  '& table > thead > tr > th:first-of-type': {
    position: 'sticky',
    left: 0,
    width: RowLabelWidth,
    minWidth: RowLabelWidth,
    zIndex: 2,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    fontSize: theme.typography.h4.fontSize,
    fontFamily: theme.typography.body1.fontFamily,
    backgroundColor: theme.palette.common.white,
  },
  '& table > tbody > tr > td:first-of-type': {
    position: 'sticky',
    left: 0,
    width: RowLabelWidth,
    minWidth: RowLabelWidth,
    maxWidth: RowLabelWidth,
    zIndex: 2,
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    verticalAlign: 'top',
    ...theme.typography.h4,
    '&:after': {
      content: '""',
      position: 'absolute',
      right: -1,
      top: 0,
      bottom: 0,
      width: 18,
      background: 'linear-gradient(90deg, rgba(250,250,251,0) 0%, rgba(244,244,244,1) 100%)',
    },
  },
}));

export const Container = styled(Box)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.common.secondary200}`,
  display: 'flex',
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  marginBottom: theme.spacing(-4),
}));

export const TableRow = styled(MuiTableRow)(({ theme }) => ({
  '& > th,& > td': {
    backgroundColor: theme.palette.common.white,
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  },
}));

export const TableRowDark = styled(MuiTableRow, {
  shouldForwardProp: (prop) => shouldForwardProp<TableRowDarkProps>(['last'], prop),
})<TableRowDarkProps>(({ theme, last }) => ({
  '& > th,& > td': {
    backgroundColor: '#fafafb',
    borderBottom: last ? `1px solid ${theme.palette.common.secondary200}` : 'none',
  },
}));

export const WaitingPeriod = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  gap: theme.spacing(1),
  fontFamily: theme.typography.h5.fontFamily,
}));

export const ReasonDecisionAccordion = styled(ProductQuoteDecisionAccordion)(({ theme }) => ({
  '& .MuiAccordionSummary-root': {
    borderTop: 'none',
    borderBottom: `1px solid ${theme.palette.common.secondary200}`,
  },
  '& .MuiCollapse-root:after': {
    content: '""',
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    width: 1,
    background: theme.palette.common.white,
  },
}));

export default TableContainerStyled;
