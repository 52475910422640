import React from 'react';
import {
  Stack,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { roundToHundred, roundToOneDecimal } from '../../../../utils/converters';
import { BeneficiariesTotalOutput } from './beneficiaries-panel.styles';

interface BeneficiariesTotalProps extends TypographyProps {
  total: number
}

function BeneficiariesTotal({ total, ...props }: BeneficiariesTotalProps) {
  const { t } = useTranslation();

  return (
    <Stack gap={6} direction="row" justifyContent="space-between">
      <Typography variant="body1">
        {t('components.beneficiariesTotal.totalMessage')}
      </Typography>
      <Typography variant="body1" {...props}>
        <strong>{`${t('components.beneficiariesTotal.total')} `}</strong>
        <BeneficiariesTotalOutput
          component="span"
          data-testid="beneficiaries-total"
          showError={total > 100}
          sx={{ minWidth: 50, display: 'inline-block', textAlign: 'right' }}
        >
          {`${roundToHundred(roundToOneDecimal(total))}%`}
        </BeneficiariesTotalOutput>
      </Typography>
    </Stack>
  );
}

export default BeneficiariesTotal;
